import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface VideoData {
  user_id: string;
  event: string;
  event_detail: string;
  event_detail_2: string;
  event_detail_3: string;
  event_timestamp: string;
  device_id: string;
  section_level: string;
  section: string;
  session_id: string;
  event_type: string;
  event_id: string;
}

// Structure for grouped data
interface GroupedData {
  [section: string]: {
    completed: number;
    skipped: number;
  };
}

const Videos: React.FC = () => {
  const [videoData, setVideoData] = useState<VideoData[]>([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from the given Videos endpoint
  const fetchVideoData = async () => {
    try {
      const response = await fetch(
        "https://ob38jihfoj.execute-api.us-east-1.amazonaws.com/dev/api/v1/metric-queries/3fe35ee7-0862-4e9a-9c59-d6a196e0a667"
      );
      const apiData = await response.json();
      // Transform the raw API data to our desired structure
      const transformed = transformVideoData(apiData);
      setVideoData(transformed);
    } catch (error) {
      console.error("Error fetching video data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Transform the raw data from the API
  const transformVideoData = (apiData: any[]): VideoData[] => {
    // The data structure is [ [header...], [ [row], [row], ... ] ]
    if (!apiData || !apiData[1] || !apiData[1].length) return [];

    // Skips the top-level header row, then the internal header row, to get just the event rows
    return apiData[1].slice(1).map((row: string[]) => ({
      user_id: row[0],
      event: row[1],
      event_detail: row[2],
      event_detail_2: row[3],
      event_detail_3: row[4],
      event_timestamp: row[5],
      device_id: row[6],
      section_level: row[7],
      section: row[8],
      session_id: row[9],
      event_type: row[10],
      event_id: row[11],
    }));
  };

  // Helper function to process video data for the stacked bar chart
  const processVideoData = () => {
    const groupedData: GroupedData = {};

    videoData.forEach((event) => {
      const section = event.section || "Unknown Section";
      if (!groupedData[section]) {
        groupedData[section] = { completed: 0, skipped: 0 };
      }

      if (event.event_type === "Video_Completed") {
        groupedData[section].completed += 1;
      } else if (event.event_type === "Video_Skipped") {
        groupedData[section].skipped += 1;
      }
    });

    const labels = Object.keys(groupedData);

    return {
      labels,
      completedData: labels.map((section) => groupedData[section].completed),
      skippedData: labels.map((section) => groupedData[section].skipped),
      // For percentage calculations in the tooltip
      totalData: labels.map(
        (section) =>
          groupedData[section].completed + groupedData[section].skipped
      ),
    };
  };

  useEffect(() => {
    fetchVideoData();
  }, []);

  if (loading) {
    return <div>Loading Video data...</div>;
  }

  // Prepare the chart data
  const { labels, completedData, skippedData, totalData } = processVideoData();

  const videosChartData = {
    labels,
    datasets: [
      {
        label: "Completed",
        data: completedData,
        backgroundColor: "#3366cc",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Skipped",
        data: skippedData,
        backgroundColor: "#dc3912",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Custom tooltip to show percentage
  const barOptions = {
    plugins: {
      legend: {
        position: "top" as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const datasetIndex = context.datasetIndex; // 0 -> Completed, 1 -> Skipped
            const dataIndex = context.dataIndex; // index of the label
            const label = context.dataset.label; // "Completed" or "Skipped"
            const value = context.parsed.y; // numeric value of this bar
            const total = totalData[dataIndex] || 0;

            let percentage = 0;
            if (total > 0) {
              percentage = (value / total) * 100;
            }
            const percentageStr = percentage.toFixed(2);

            return `${label}: ${value} of ${total} (${percentageStr}%)`;
          },
        },
      },
    },
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };

  return (
    <div>
      <h2>Videos</h2>
      <Bar data={videosChartData} options={barOptions} />
    </div>
  );
};

export default Videos;

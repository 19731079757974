// Lesson_New.tsx
import React from "react";
import SentenceTranslationGenerator from "./SentenceTranslation";

const Lesson_New: React.FC = () => {
  return (
    <div>
      <SentenceTranslationGenerator />
    </div>
  );
};

export default Lesson_New;

import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface MultipleChoiceData {
  user_id: string;
  event: string;
  event_detail: string;
  event_detail_2: string;
  event_detail_3: string;
  event_timestamp: string;
  device_id: string;
  section_level: string;
  section: string;
  session_id: string;
  event_type: string;
  event_id: string;
}

// Structure for grouped data
interface GroupedData {
  [section: string]: {
    correct: number;
    incorrect: number;
  };
}

const MultipleChoiceChart: React.FC = () => {
  const [multipleChoiceData, setMultipleChoiceData] = useState<MultipleChoiceData[]>([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from new endpoint
  const fetchMultipleChoiceData = async () => {
    try {
      const response = await fetch(
        "https://ob38jihfoj.execute-api.us-east-1.amazonaws.com/dev/api/v1/metric-queries/e7946b84-13f9-40e3-a0c8-283397856f23"
      );
      const apiData = await response.json();
      // Transform the raw API data to our desired structure
      const transformed = transformMultipleChoiceData(apiData);
      setMultipleChoiceData(transformed);
    } catch (error) {
      console.error("Error fetching multiple choice data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Transform the raw data from the API
  const transformMultipleChoiceData = (apiData: any[]): MultipleChoiceData[] => {
    // The data structure is [ [header...], [ [row], [row], ... ] ]
    // So we want apiData[1].slice(1) -> all rows except the header row
    // Then map them to a typed interface
    if (!apiData || !apiData[1] || !apiData[1].length) return [];

    // Skips the top-level header row, then the internal header row, to get just the event rows
    return apiData[1].slice(1).map((row: string[]) => ({
      user_id: row[0],
      event: row[1],
      event_detail: row[2],
      event_detail_2: row[3],
      event_detail_3: row[4],
      event_timestamp: row[5],
      device_id: row[6],
      section_level: row[7],
      section: row[8],
      session_id: row[9],
      event_type: row[10],
      event_id: row[11],
    }));
  };

  // Helper function to process multiple choice data for the stacked bar chart
  const processMultipleChoiceData = () => {
    const groupedData: GroupedData = {};

    multipleChoiceData.forEach((event) => {
      const section = event.section || "Unknown Section";
      if (!groupedData[section]) {
        groupedData[section] = { correct: 0, incorrect: 0 };
      }

      if (event.event_type === "mark_correct") {
        groupedData[section].correct += 1;
      } else if (event.event_type === "mark_incorrect") {
        groupedData[section].incorrect += 1;
      }
    });

    // Return arrays needed for Chart.js
    return {
      labels: Object.keys(groupedData),
      correctData: Object.values(groupedData).map((data) => data.correct),
      // If you want incorrect to show as negative, you can do -data.incorrect
      // otherwise remove the negative sign.
      incorrectData: Object.values(groupedData).map((data) => -data.incorrect),
    };
  };

  useEffect(() => {
    fetchMultipleChoiceData();
  }, []);

  if (loading) {
    return <div>Loading Multiple Choice data...</div>;
  }

  // Prepare the chart data
  const { labels, correctData, incorrectData } = processMultipleChoiceData();

  const multipleChoiceChartData = {
    labels,
    datasets: [
      {
        label: "Correct",
        data: correctData,
        backgroundColor: "#3366cc",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Incorrect",
        data: incorrectData,
        backgroundColor: "#dc3912",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };

  return (
    <div>
      <h2>Multiple Choice Questions</h2>
      <Bar data={multipleChoiceChartData} options={barOptions} />
    </div>
  );
};

export default MultipleChoiceChart;

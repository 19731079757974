// Trivia.tsx
import React, { useState, useCallback, useRef } from "react";
import styles from "./Trivia.module.css";
import {
  buildGenAiTopicPayload,
  buildGenAiTriviaEnglishPayload,
  buildGenAiTriviaTranslationPayload,
  buildTriviaCustomImagePayload,
  buildTriviaInitialImagePayload,
} from "../GenAIPrompts/GenAIPrompts";
// Import the helper function instead of calling the route directly.
import { callOpenAI, generateImage } from "../API/API"

// Possible difficulty levels
const DIFFICULTY_LEVELS = ["A1", "A2", "B1", "B2", "C1", "C2"];

// Possible genres
const GENRES = [
  "History",
  "Science",
  "Geography",
  "Art",
  "Sports",
  "Entertainment",
];

// Interfaces that mirror the expected JSON structure
interface TriviaQuestion {
  question: string;
  options: string[];
  answer: string;
}

interface QuestionsByLanguage {
  language: string;
  questions: TriviaQuestion[];
}

interface TriviaData {
  topic: string;
  questionsByLanguage: QuestionsByLanguage[];
}

const Trivia: React.FC = () => {
  const [selectedDifficulty, setSelectedDifficulty] = useState("A1");
  const [selectedGenre, setSelectedGenre] = useState("History");

  const [generatedTopic, setGeneratedTopic] = useState("");
  const [triviaData, setTriviaData] = useState<TriviaData | null>(null);

  const [topicImageUrl, setTopicImageUrl] = useState<string | null>(null);

  const [isGeneratingTopic, setIsGeneratingTopic] = useState(false);
  const [isGeneratingTrivia, setIsGeneratingTrivia] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // New state for storing the image URLs for each English question.
  const [englishImageData, setEnglishImageData] = useState<
    { url: string | null; isRegenerating: boolean }[]
  >([]);

  // New state for storing custom prompts for each English question.
  const [customPrompts, setCustomPrompts] = useState<string[]>([]);

  // Edit modal state
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalData, setEditModalData] = useState<{
    language: string;
    questionIndex: number;
    questionText: string;
    options: string[];
    answer: string;
  } | null>(null);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const language = "English";

  // ========== Helper function to parse the AI response as JSON ========== //
  const parseJsonResponse = (rawString: string) => {
    // 1) Remove any triple backticks or code fences
    let cleanedString = rawString.replace(/```(json)?|```/g, "").trim();

    // 2) Attempt to find the first '{' and the last '}'
    const startIndex = cleanedString.indexOf("{");
    const endIndex = cleanedString.lastIndexOf("}") + 1;

    if (startIndex === -1 || endIndex <= startIndex) {
      throw new Error("Could not find a valid JSON object in the response.");
    }

    // 3) Extract just the JSON portion
    cleanedString = cleanedString.slice(startIndex, endIndex).trim();

    // 4) Parse it
    return JSON.parse(cleanedString);
  };

  // ========== Generate Topic ========== //
  const handleGenerateTopic = useCallback(async () => {
    try {
      setIsGeneratingTopic(true);
      setError(null);

      const payload = buildGenAiTopicPayload(selectedDifficulty, selectedGenre);
      // Instead of using fetch, call the helper function.
      const response = await callOpenAI(payload);
      if (!response) {
        throw new Error("API error: No response from backend.");
      }

      const jsonString = response.response || "";
      // Parse the JSON
      const data = parseJsonResponse(jsonString);

      if (!data.topic) {
        throw new Error("No 'topic' found in the response JSON.");
      }

      setGeneratedTopic(data.topic);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsGeneratingTopic(false);
    }
  }, [selectedDifficulty, selectedGenre]);

  // ========== Generate Trivia in Smaller Chunks ==========
  const handleGenerateTrivia = useCallback(async () => {
    if (!generatedTopic) {
      alert("Please generate a topic first.");
      return;
    }
    try {
      setIsGeneratingTrivia(true);
      setError(null);

      // Step 1: Generate English trivia questions
      const englishPayload = buildGenAiTriviaEnglishPayload(
        selectedDifficulty,
        selectedGenre,
        generatedTopic
      );

      const englishResponse = await callOpenAI(englishPayload);
      if (!englishResponse) {
        throw new Error("English API error: No response from backend.");
      }
      const englishJsonString = englishResponse.response || "";
      const englishData = JSON.parse(englishJsonString); // Expected to be { topic, questions: [...] }
      const englishQuestions = englishData.questions;
      if (!englishQuestions || !Array.isArray(englishQuestions)) {
        throw new Error("Invalid English questions format.");
      }

      // Initialize customPrompts state for each question if not already set.
      setCustomPrompts(new Array(englishQuestions.length).fill(""));
      // Auto-generate images for each English question:
      const imagePromises = englishQuestions.map((q: any, idx: number) =>
        handleGenerateTriviaImage(q.question, idx)
      );
      const images = await Promise.all(imagePromises);
      setEnglishImageData(
        images.map((url) => ({ url: url, isRegenerating: false }))
      );

      // Step 2: For each target language (other than English), translate the questions.
      const targetLanguages = [
        "French",
        "Spanish",
        "Italian",
        "German",
        "Portuguese",
      ];
      const translationPromises = targetLanguages.map(async (lang) => {
        const translationPayload = buildGenAiTriviaTranslationPayload(
          selectedDifficulty,
          selectedGenre,
          generatedTopic,
          lang,
          englishQuestions
        );
        const translationResponse = await callOpenAI(translationPayload);
        if (!translationResponse) {
          throw new Error(
            `Translation API error for ${lang}: No response from backend.`
          );
        }
        const translationJsonString = translationResponse.response || "";
        const translationData = JSON.parse(translationJsonString); // Expected to be { language, questions: [...] }
        return translationData;
      });

      const translations = await Promise.all(translationPromises);

      // Step 3: Combine English and translations into one trivia data structure
      const triviaDataCombined = {
        topic: generatedTopic,
        questionsByLanguage: [
          {
            language: "English",
            questions: englishQuestions,
          },
          ...translations, // each already has { language, questions }
        ],
      };

      setTriviaData(triviaDataCombined);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsGeneratingTrivia(false);
    }
  }, [generatedTopic, selectedDifficulty, selectedGenre]);

  // ========== Handle Image Upload ========== //
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const localUrl = URL.createObjectURL(file);
      setTopicImageUrl(localUrl);
    }
  };

  const handleGenerateTriviaImage = async (
    questionText: string,
    qIndex: number
  ) => {
    // Default style instructions including the "no text" requirement.
    const styleInstructions = `It should be animated yet somewhat realistic. Make it lively with the subject clearly shown. Easy to identify for ${language} learners. The image should be lively, engaging, and detailed. The image should contain no text.`;
  
    let payload;
    // Choose the custom or default payload based on whether a custom prompt was provided.
    if (customPrompts[qIndex] && customPrompts[qIndex].trim() !== "") {
      payload = buildTriviaCustomImagePayload(
        generatedTopic,
        questionText,
        customPrompts[qIndex],
        language,
        styleInstructions // Pass the style instructions
      );
    } else {
      payload = buildTriviaInitialImagePayload(
        generatedTopic,
        questionText,
        language,
        styleInstructions // Pass the style instructions
      );
    }
  
    // Call the centralized helper function to generate the image.
    return await generateImage(payload);
  };

  const handleRegenerateTriviaImage = async (qIndex: number) => {
    if (!triviaData || triviaData.questionsByLanguage.length === 0) return;
    const englishQuestions = triviaData.questionsByLanguage.find(
      (q) => q.language === "English"
    )?.questions;
    if (!englishQuestions || !englishQuestions[qIndex]) return;

    // Set the regenerating flag for this question.
    setEnglishImageData((prev) => {
      const newData = [...prev];
      newData[qIndex] = {
        ...(newData[qIndex] || { url: null, isRegenerating: false }),
        isRegenerating: true,
      };
      return newData;
    });

    // Generate a new image URL.
    const newUrl = await handleGenerateTriviaImage(
      englishQuestions[qIndex].question,
      qIndex
    );
    if (newUrl) {
      setEnglishImageData((prev) => {
        const newData = [...prev];
        newData[qIndex] = { url: newUrl, isRegenerating: false };
        return newData;
      });
    } else {
      // If failed, reset isRegenerating to false.
      setEnglishImageData((prev) => {
        const newData = [...prev];
        if (newData[qIndex]) {
          newData[qIndex].isRegenerating = false;
        }
        return newData;
      });
    }
  };

  const handleCustomPromptChange = (qIndex: number, value: string) => {
    setCustomPrompts((prev) => {
      const newPrompts = [...prev];
      newPrompts[qIndex] = value;
      return newPrompts;
    });
  };

  // ========== Remove a Question ==========
  const handleRemoveQuestion = (language: string, questionIndex: number) => {
    if (!triviaData) return;

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this question?"
    );
    if (!isConfirmed) return;

    const updated = { ...triviaData };
    const langData = updated.questionsByLanguage.find(
      (q) => q.language === language
    );
    if (!langData) return;

    langData.questions.splice(questionIndex, 1);
    setTriviaData(updated);
  };

  // ========== Edit a Question ==========
  const handleEditQuestion = (
    language: string,
    questionIndex: number,
    questionObj: TriviaQuestion
  ) => {
    setEditModalData({
      language,
      questionIndex,
      questionText: questionObj.question,
      options: [...questionObj.options],
      answer: questionObj.answer,
    });
    setIsEditModalOpen(true);

    setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 100);
  };

  const handleSaveEdit = () => {
    if (!triviaData || !editModalData) return;
    const { language, questionIndex, questionText, options, answer } =
      editModalData;

    const updated = { ...triviaData };
    const langData = updated.questionsByLanguage.find(
      (q) => q.language === language
    );
    if (!langData) return;

    langData.questions[questionIndex] = {
      question: questionText,
      options,
      answer,
    };

    setTriviaData(updated);
    setIsEditModalOpen(false);
    setEditModalData(null);
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
    setEditModalData(null);
  };

  const handleSave = () => {
    alert("Saved! (In a real app, you’d POST this data to your server.)");
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Trivia</h1>

      {/* ========== FORM: Difficulty & Genre ========== */}
      <div className={styles.formRow}>
        <label className={styles.label}>
          <strong>Difficulty:</strong>
          <select
            value={selectedDifficulty}
            onChange={(e) => setSelectedDifficulty(e.target.value)}
            className={styles.input}
          >
            {DIFFICULTY_LEVELS.map((level) => (
              <option key={level} value={level}>
                {level}
              </option>
            ))}
          </select>
        </label>

        <label className={styles.label}>
          <strong>Genre:</strong>
          <select
            value={selectedGenre}
            onChange={(e) => setSelectedGenre(e.target.value)}
            className={styles.input}
          >
            {GENRES.map((g) => (
              <option key={g} value={g}>
                {g}
              </option>
            ))}
          </select>
        </label>

        <button
          onClick={handleGenerateTopic}
          disabled={isGeneratingTopic}
          className={styles.button}
        >
          {isGeneratingTopic ? "Generating Topic..." : "Generate Topic"}
        </button>

        <button
          onClick={handleGenerateTrivia}
          disabled={!generatedTopic || isGeneratingTrivia}
          className={styles.button}
        >
          {isGeneratingTrivia ? "Generating Trivia..." : "Generate Trivia"}
        </button>
      </div>

      {generatedTopic && (
        <p className={styles.topicDisplay}>
          <strong>Topic:</strong> {generatedTopic}
        </p>
      )}

      {error && <p className={styles.error}>Error: {error}</p>}

      {/* ========== TABLE of Trivia Data ========== */}
      {triviaData && (
        <div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Language</th>
                <th>Questions & Answers</th>
                <th>Image</th>
              </tr>
            </thead>
            <tbody>
              {triviaData.questionsByLanguage.map((langData, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    <strong>{langData.language}</strong>
                  </td>
                  <td>
                    {langData.questions.map((q, i) => (
                      <div key={i} className={styles.questionBlock}>
                        <p>
                          <strong>Question {i + 1}:</strong> {q.question}
                        </p>
                        <p>
                          <strong>Options:</strong> {q.options.join(", ")}
                        </p>
                        <p>
                          <strong>Answer:</strong> {q.answer}
                        </p>
                        <div className={styles.qActions}>
                          <button
                            onClick={() =>
                              handleEditQuestion(langData.language, i, q)
                            }
                            className={styles.smallButton}
                          >
                            Edit
                          </button>
                          <button
                            onClick={() =>
                              handleRemoveQuestion(langData.language, i)
                            }
                            className={styles.smallButton}
                          >
                            Remove
                          </button>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </td>

                  <td className={styles.imageCell}>
                    {englishImageData.map((data, i) => (
                      <div key={i}>
                        <img
                          src={data.url || ""}
                          alt={`Image for question ${i + 1}`}
                          className={styles.image}
                        />
                        {langData.language === "English" && (
                          <div>
                            <input
                              type="text"
                              value={customPrompts[i] || ""}
                              placeholder="Enter prompt (e.g., A cartoonish, semi-realistic …)"
                              onChange={(e) =>
                                handleCustomPromptChange(i, e.target.value)
                              }
                              className={`${styles.input} ${styles.imageInput}`}
                            />
                            <button
                              onClick={() => handleRegenerateTriviaImage(i)}
                              className={styles.button}
                              disabled={data.isRegenerating}
                            >
                              {data.isRegenerating
                                ? "Regenerating..."
                                : "Regenerate"}
                            </button>
                            <br />
                            <br />
                          </div>
                        )}
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={handleSave} className={styles.saveButton}>
            Save
          </button>
          <br />
          <br />
        </div>
      )}

      {/* ========== EDIT MODAL ========== */}
      {isEditModalOpen && editModalData && (
        <div ref={modalRef} className={styles.modalBackdrop}>
          <div className={styles.modal}>
            <h2>Edit Question</h2>
            <label className={styles.modalEdit}>
              <strong>Question Text:</strong>
              <textarea
                value={editModalData.questionText}
                onChange={(e) =>
                  setEditModalData((prev) =>
                    prev ? { ...prev, questionText: e.target.value } : null
                  )
                }
                className={styles.modalInput}
              />
            </label>
            <br />
            <label className={styles.modalEdit}>
              <strong>Options (comma separated):</strong>
              <input
                type="text"
                value={editModalData.options.join(", ")}
                onChange={(e) => {
                  const newOpts = e.target.value
                    .split(",")
                    .map((o) => o.trim());
                  setEditModalData((prev) =>
                    prev ? { ...prev, options: newOpts } : null
                  );
                }}
                className={styles.modalInput}
              />
            </label>
            <br />
            <label className={styles.modalEdit}>
              <strong>Correct Answer:</strong>
              <input
                type="text"
                value={editModalData.answer}
                onChange={(e) =>
                  setEditModalData((prev) =>
                    prev ? { ...prev, answer: e.target.value } : null
                  )
                }
                className={styles.modalInput}
              />
            </label>

            <div className={styles.modalButtons}>
              <button onClick={handleSaveEdit} className={styles.button}>
                Save Text
              </button>
              <button onClick={handleCancelEdit} className={styles.button}>
                Cancel
              </button>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default Trivia;

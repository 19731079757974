// src/DurationHistogram/DurationHistogram.tsx
import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { AvgTimeEvent } from "../../Metrics/Metrics";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface DurationHistogramProps {
  events: AvgTimeEvent[]; // Reusing existing type from Metrics.tsx
}

const Duration: React.FC<DurationHistogramProps> = ({ events }) => {
  // Process duration data into bins
  const { labels, counts } = useMemo(() => {
    // Create 1-minute bins up to 10 minutes + overflow
    const binSize = 60; // 60 seconds = 1 minute
    const bins = Array.from({ length: 10 }, (_, i) => ({
      min: i * binSize,
      max: (i + 1) * binSize,
      label: `${i}-${i + 1} min`,
    }));
    bins.push({ min: 600, max: Infinity, label: "10+ min" });

    // Initialize counts
    const counts = new Array(bins.length).fill(0);

    // Count durations into bins
    events.forEach(({ duration }) => {
      const binIndex = bins.findIndex(
        (b) => duration >= b.min && duration < b.max
      );
      if (binIndex >= 0) counts[binIndex]++;
    });

    return {
      labels: bins.map((b) => b.label),
      counts,
    };
  }, [events]);

  if (events.length === 0) {
    return (
      <div>
        <h2>Session Duration Distribution</h2>
        <p>No data available.</p>
      </div>
    );
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: "Number of Sessions",
        data: counts,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <h2>Session Duration Distribution</h2>
      <Bar
        data={chartData}
        options={{
          scales: {
            x: { title: { display: true, text: "Duration" } },
            y: { title: { display: true, text: "Number of Sessions" } },
          },
        }}
      />
    </div>
  );
};

export default Duration;

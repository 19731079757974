import axios from "axios";
import {
  VocabularyData,
  DynamoDBItem,
  // TableData,
  CourseData,
  ApiResponse,
  Section,
  LessonData,
  ImageDataUpload,
  ImageUploadResponse,
  PassageData,
  TriviaData,
} from "./types";

const mobileAppUri = "https://jb3lo2ttk6.execute-api.us-east-1.amazonaws.com";
const dataLoaderUri =
  "https://duaipi78ei.execute-api.us-east-1.amazonaws.com/juno-middleware";

// Fetch course data
export const fetchCourseData = async (courseNumber: string) => {
  try {
    const response = await axios.get(`${mobileAppUri}/api/v1/lesson`, {
      params: {
        courseNumber: courseNumber,
      },
    });

    // Assuming that the response is structured similarly to your previous API
    return response.data;
  } catch (error) {
    console.log("Error fetching course data:", error);
    throw error;
  }
};

// Function to fetch vocabulary data
export const fetchVocabularyData = async (
  section: string
): Promise<VocabularyData[]> => {
  const uri = "https://4h3ddskfpb.execute-api.us-east-1.amazonaws.com";

  try {
    const response = await fetch(`${uri}/api/v1/vocabulary?section=${section}`);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorText}`
      );
    }

    const jsonResponse = await response.json();

    // Check if 'items' exists and is an array
    if (!jsonResponse.items || !Array.isArray(jsonResponse.items)) {
      throw new Error(
        "Invalid API response structure: 'items' array is missing."
      );
    }

    // Transform the response to match the expected data structure
    const vocabularyList: VocabularyData[] = jsonResponse.items.map(
      (item: any) => ({
        Identifier: item.Identifier?.S ?? "",
        Level: "", // Leaving as an empty string to match the original structure
        Section: item.Level?.S ?? "",
        SpanishWord: item.SpanishWord?.S ?? "",
        EnglishWord: item.BaseWord?.S ?? "",
        ImageUrl: item.ImageUrl?.S ?? "",
        SpanishOptions:
          item.SpanishOptions?.L?.map((option: { S: string }) => option.S) ??
          [],
        EnglishOptions:
          item.EnglishOptions?.L?.map((option: { S: string }) => option.S) ??
          [],
        // Include other fields if necessary, defaulting to match original structure
      })
    );

    return vocabularyList;
  } catch (error: any) {
    console.log("Error fetching vocabulary data:", error);
    throw new Error(
      `Failed to fetch vocabulary data for section ${section}: ${error.message}`
    );
  }
};

// Upload cropped image
// export const uploadImage = async (fileName: string, fileType: string, blob: Blob) => {
//   const presignedUrlResponse = await axios.post('http://languagebackend.us-east-2.elasticbeanstalk.com/api/upload-vocab-image', {
//     fileName,
//     fileType
//   });
//   const presignedUrl = presignedUrlResponse.data.url;
//   await axios.put(presignedUrl, blob, {
//     headers: {
//       'Content-Type': blob.type
//     }
//   });
//   return presignedUrl.split('?')[0];
// };

// Function to add vocabulary data
// export const addVocabulary = async (data: VocabularyData) => {
//   try {
//     // Construct the API endpoint using the new primary key fields: Identifier and Level
//     const apiUrl = `${dataLoaderUri}/api/v1/add-vocabulary/${data.Identifier}/${data.Level}`;

//     // Build the payload with the new data structure
//     const payload = {
//       Identifier: data.Identifier,
//       Level: data.Level,
//       Base_Word: data.Base_Word,
//       Base_Lang_Code: data.Base_Lang_Code,
//       Base_Lang_Options: data.Base_Lang_Options,
//       Targ_Word: data.Targ_Word,
//       Targ_Lang_Code: data.Targ_Lang_Code,
//       Targ_Lang_Options: data.Targ_Lang_Options,
//       Explanation_Word_Timing: data.Explanation_Word_Timing,
//       Phonetic_Transcription: data.Phonetic_Transcription,
//       Pronunciation_Explanation: data.Pronunciation_Explanation,
//       Pronunciation_Explanation_Audio: data.Pronunciation_Explanation_Audio,
//       Targ_Syllable: data.Targ_Syllable,
//       Targ_Syllable_Sounds: data.Targ_Syllable_Sounds,
//       Word_Audio: data.Word_Audio,
//       ImageURL: data.ImageURL,
//     };

//     // Make the POST request with the new payload structure
//     const response = await axios.post(apiUrl, JSON.stringify(payload), {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });

//     // Log status and response for debugging
//     console.log("Status Code:", response.status);
//     console.log("Response:", response.data);

//     return response.data;
//   } catch (error: any) {
//     console.log("Error adding vocabulary:", error);
//     throw new Error(`Failed to add vocabulary: ${error.message}`);
//   }
// };

// const apiUri = "http://languagebackend.us-east-2.elasticbeanstalk.com";

// Update vocabulary data
export const updateVocabulary = async (
  identifier: string,
  level: string,
  data: VocabularyData
) => {
  try {
    // Construct the API endpoint using Identifier and Level from the new data structure
    const apiUrl = `${dataLoaderUri}/api/v1/update-vocabulary/${data.Identifier}/${data.Level}`;

    // Build the payload with the new structure fields
    const payload = {
      Identifier: data.Identifier,
      Level: data.Level,
      Base_Word: data.Base_Word,
      Base_Lang_Code: data.Base_Lang_Code,
      Base_Lang_Options: data.Base_Lang_Options,
      Targ_Word: data.Targ_Word,
      Targ_Lang_Code: data.Targ_Lang_Code,
      Targ_Lang_Options: data.Targ_Lang_Options,
      Explanation_Word_Timing: data.Explanation_Word_Timing,
      Phonetic_Transcription: data.Phonetic_Transcription,
      Pronunciation_Explanation: data.Pronunciation_Explanation,
      Pronunciation_Explanation_Audio: data.Pronunciation_Explanation_Audio,
      Targ_Syllable: data.Targ_Syllable,
      Targ_Syllable_Sounds: data.Targ_Syllable_Sounds,
      Word_Audio: data.Word_Audio,
      ImageURL: data.ImageURL,
    };

    // Make the PUT request with the new payload structure
    const response = await axios.put(apiUrl, JSON.stringify(payload), {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Log the status code and response data for debugging purposes
    console.log("Status Code:", response.status);
    console.log("Response:", response.data);

    // Return the response data if the request is successful
    return response.data;
  } catch (error: any) {
    console.log("Error updating vocabulary:", error);
    throw new Error(`Failed to update vocabulary: ${error.message}`);
  }
};

// Delete Vocabulary
export const deleteVocabulary = async (data: VocabularyData) => {
  try {
    // Construct the API endpoint using Identifier and Level
    const apiUrl = `${dataLoaderUri}/api/v1/delete-vocabulary/${data.Identifier}/${data.Level}`;

    // Build the payload using the new data structure
    const payload = {
      Identifier: data.Identifier,
      Level: data.Level,
      Base_Word: data.Base_Word,
      Base_Lang_Code: data.Base_Lang_Code,
      Base_Lang_Options: data.Base_Lang_Options,
      Targ_Word: data.Targ_Word,
      Targ_Lang_Code: data.Targ_Lang_Code,
      Targ_Lang_Options: data.Targ_Lang_Options,
      Explanation_Word_Timing: data.Explanation_Word_Timing,
      Phonetic_Transcription: data.Phonetic_Transcription,
      Pronunciation_Explanation: data.Pronunciation_Explanation,
      Pronunciation_Explanation_Audio: data.Pronunciation_Explanation_Audio,
      Targ_Syllable: data.Targ_Syllable,
      Targ_Syllable_Sounds: data.Targ_Syllable_Sounds,
      Word_Audio: data.Word_Audio,
      ImageURL: data.ImageURL,
    };

    // Make the DELETE request with the updated payload
    const response = await axios.delete(apiUrl, {
      data: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Log the status code and response data for debugging purposes
    console.log("Status Code:", response.status);
    console.log("Response:", response.data);

    // Return the response data if the request is successful
    return response.data;
  } catch (error: any) {
    console.log("Error deleting vocabulary:", error);
    throw new Error(`Failed to delete vocabulary: ${error.message}`);
  }
};

export async function fetchAllSections(): Promise<string[]> {
  const uri =
    "https://ob38jihfoj.execute-api.us-east-1.amazonaws.com/dev/api/v1/all-sections/1";

  try {
    const response = await fetch(uri);
    const data: ApiResponse = await response.json();

    // Extract the identifiers from the sections
    const identifiers = data.sections.map((section) => section.Identifier);

    return identifiers;
  } catch (error) {
    console.log("Error fetching sections:", error);
    return [];
  }
}

// Function to update the course
export const updateCourse = async (courseData: CourseData) => {
  // Assign the variables to the request body
  const body = {
    Identifier: courseData.Identifier,
    City: courseData.City,
    Country: courseData.Country,
    Description: courseData.Description,
    Images: courseData.Images,
    Vocabulary_List: courseData.Vocabulary_List,
  };

  try {
    const response = await axios.put(
      `${dataLoaderUri}/api/v1/update-course/${courseData.Identifier}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Print the response in JSON format
    console.log(response.data);
  } catch (error) {
    console.log("Error updating course:", error);
  }
};

export async function fetchSectionData(section: string): Promise<Section[]> {
  const uri = "https://4h3ddskfpb.execute-api.us-east-1.amazonaws.com";

  try {
    const response = await axios.get(`${uri}/api/v1/sections`, {
      params: { section },
    });

    // Map over response.data.items instead of response.data
    const data = response.data.items.map(
      (item: any): Section => ({
        Identifier: item.Identifier.S,
        Lessons: item.Lessons.L.map((lesson: any): LessonData => {
          const imageInfo = lesson.M.ImageInfo?.M || {};

          // Safely extract ImageUrl and ImageObjects
          const imageUrl = imageInfo.ImageUrl?.S;
          const imageObjectsStr = imageInfo.ImageObjects?.S;

          let imageObjects = [];
          if (imageObjectsStr) {
            try {
              imageObjects = JSON.parse(imageObjectsStr); // Parse ImageObjects if present
            } catch (e) {
              console.log("Error parsing ImageObjects:", e);
            }
          }

          const itemDescribeWords =
            lesson.M.ItemDescribeWords?.L?.map((word: any) => word.S) || [];

          // Extract Sentence Translation specific fields as arrays
          const sentenceToBeTranslated =
            lesson.M.SentenceToBeTranslated?.L?.map(
              (sentence: any) => sentence.S
            ) || [];
          const translatedSentence =
            lesson.M.TranslatedSentence?.L?.map(
              (sentence: any) => sentence.S
            ) || [];
          const additionalWords =
            lesson.M.AdditionalWords?.L?.map((word: any) => word.S) || [];

          // Extract VideoQuestions specific fields
          let videoQuestions = undefined;
          if (lesson.M.VideoQuestions) {
            videoQuestions = {
              VideoUrl: lesson.M.VideoQuestions.M.VideoUrl?.S || "",
              Questions:
                lesson.M.VideoQuestions.M.Questions?.L.map((q: any) => ({
                  question: q.M.question?.S || "",
                  correctOption: q.M.correctOption?.S || "",
                  options:
                    q.M.options?.L.slice(0, 3).map((opt: any) => opt.S) || [], // Limit options to 3
                })) || [],
            };
          }

          // Extract Review specific fields if lesson type is 'Review'
          const reviewSection =
            lesson.M.Type.S === "Review"
              ? lesson.M.ReviewSection?.S
              : undefined;
          const reviewComponent =
            lesson.M.Type.S === "Review"
              ? lesson.M.ReviewComponent?.S
              : undefined;

          return {
            Type: lesson.M.Type.S,
            Video: lesson.M.Video?.S,
            VideoContentText: lesson.M.VideoContentText?.S,
            VideoTitle: lesson.M.VideoTitle?.S,
            PassageId: lesson.M.PassageId?.S,
            Objectives: lesson.M.Objectives?.L?.map(
              (objective: any) => objective.S
            ),
            LLM_Prompt: lesson.M.LLM_Prompt?.S,
            Character: lesson.M.Character?.S,
            ImageUrl: imageUrl,
            ImageObjects: imageObjects,
            ItemDescribeWords: itemDescribeWords,
            SentenceToBeTranslated: sentenceToBeTranslated,
            TranslatedSentence: translatedSentence,
            AdditionalWords: additionalWords,
            VideoQuestions: videoQuestions, // Include VideoQuestions
            ReviewSection: reviewSection, // Include ReviewSection if applicable
            ReviewComponent: reviewComponent, // Include ReviewComponent if applicable
            imageFile: undefined,
            croppedBlob: undefined,
          };
        }),
      })
    );

    console.log(data);
    return data;
  } catch (err: any) {
    console.log("Error fetching section data:", err);
    throw new Error(err.message);
  }
}

export async function updateSection(
  identifier: string,
  sectionData: Section
): Promise<void> {
  console.log("Section Data Before Transformation:", sectionData); // Debugging line

  const uri = "https://ob38jihfoj.execute-api.us-east-1.amazonaws.com/dev";

  const body = {
    Identifier: { S: identifier },
    Lessons: {
      L: sectionData.Lessons.map((lesson: LessonData) => ({
        M: {
          Type: { S: lesson.Type },
          Video: lesson.Video ? { S: lesson.Video } : undefined,
          VideoTitle: lesson.VideoTitle ? { S: lesson.VideoTitle } : undefined,
          VideoContentText: lesson.VideoContentText
            ? { S: lesson.VideoContentText }
            : undefined,
          PassageId: lesson.PassageId ? { S: lesson.PassageId } : undefined,
          ImageInfo:
            lesson.ImageUrl || lesson.ImageObjects
              ? {
                  M: {
                    ImageUrl: lesson.ImageUrl
                      ? { S: lesson.ImageUrl }
                      : undefined,
                    ImageObjects: lesson.ImageObjects
                      ? { S: JSON.stringify(lesson.ImageObjects) }
                      : undefined,
                  },
                }
              : undefined,
          Objectives:
            lesson.Type === "Conversation" && lesson.Objectives
              ? {
                  L: lesson.Objectives.map((obj) => ({ S: obj })),
                }
              : undefined,
          LLM_Prompt: lesson.LLM_Prompt ? { S: lesson.LLM_Prompt } : undefined,
          Character: lesson.Character ? { S: lesson.Character } : undefined, // Ensure Character is included
          ItemDescribeWords:
            lesson.Type === "ItemDescribe" && lesson.ItemDescribeWords
              ? {
                  L: lesson.ItemDescribeWords.map((word: string) => ({
                    S: word,
                  })),
                }
              : undefined,
          SentenceToBeTranslated:
            lesson.Type === "Sentence_Translation" &&
            lesson.SentenceToBeTranslated
              ? {
                  L: lesson.SentenceToBeTranslated.map((sentence: string) => ({
                    S: sentence,
                  })),
                }
              : undefined,
          TranslatedSentence:
            lesson.Type === "Sentence_Translation" && lesson.TranslatedSentence
              ? {
                  L: lesson.TranslatedSentence.map((sentence: string) => ({
                    S: sentence,
                  })),
                }
              : undefined,
          AdditionalWords:
            lesson.Type === "Sentence_Translation" && lesson.AdditionalWords
              ? {
                  L: lesson.AdditionalWords.map((word: string) => ({
                    S: word,
                  })),
                }
              : undefined,

          VideoQuestions:
            lesson.Type === "Video_Questions" && lesson.VideoQuestions
              ? {
                  M: {
                    VideoUrl: lesson.VideoQuestions.VideoUrl
                      ? { S: lesson.VideoQuestions.VideoUrl }
                      : undefined,
                    Questions: {
                      L: lesson.VideoQuestions.Questions.map((question) => ({
                        M: {
                          question: { S: question.question },
                          correctOption: { S: question.correctOption },
                          options: {
                            L: question.options
                              .slice(0, 3)
                              .map((option) => ({ S: option })), // Limit to 3 options
                          },
                        },
                      })),
                    },
                  },
                }
              : undefined,

          ReviewSection:
            lesson.Type === "Review" && lesson.ReviewSection
              ? { S: lesson.ReviewSection }
              : undefined,
          ReviewComponent:
            lesson.Type === "Review" && lesson.ReviewComponent
              ? { S: lesson.ReviewComponent }
              : undefined,

          ShowAdBefore:
            lesson.ShowAdBefore !== undefined
              ? { BOOL: lesson.ShowAdBefore }
              : undefined,
        },
      })),
    },
  };

  console.log("API Request Body:", body); // Debugging line

  try {
    const response = await axios.put(
      `${uri}/api/v1/update-section/${identifier}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("API Response Status:", response.status);
    console.log("API Response Data:", response.data);
  } catch (error) {
    console.log("Error updating section:", error);
    throw error;
  }
}

// Function to upload the city image and get the S3 URL
// export async function uploadImage(identifier: string, imageFile: File): Promise<string> {
//     // const dataLoaderUri = 'https://ob38jihfoj.execute-api.us-east-1.amazonaws.com/dev'; // Replace with actual API URI

//     // Construct the form data to include the file
//     const formData = new FormData();
//     formData.append('file_name', imageFile); // 'file' should match the API's expected parameter
//     formData.append('file_type', imageFile.type); // Append identifier if needed

//     try {
//       // Send the POST request to upload the image
//       const response = await axios.post<ImageUploadResponse>(
//         `${dataLoaderUri}/api/v1/upload-vocab-image`,  // The API endpoint to hit
//         formData,
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data'  // Ensures that the image is sent as form-data
//           }
//         }
//       );

//       // Return the URL of the uploaded image from the API response
//       return response.data.imageUrl;

//     } catch (error) {
//       console.log('Error uploading city image:', error);
//       throw error;
//     }
// }
// https://ob38jihfoj.execute-api.us-east-1.amazonaws.com/dev/api/v1/upload-vocab-image

//   export const fetchSections = async (level: string): Promise<Section> => {
//     const response = await axios.get(`${mobileAppUri}/base/sections?Level=${level}`);
//     // Assuming the API response is an array of sections
//     const sections = response.data.map((item: { Identifier: { S: string }, Lessons: any[] }) => ({
//       Identifier: item.Identifier.S,
//       Lessons: item.Lessons
//     }));

//     // Return the first section (or whatever logic fits your case)
//     return sections[0];
//   };

export const fetchPassages = async (
  section: string
): Promise<PassageData[]> => {
  const response = await axios.get(
    `${mobileAppUri}/api/v1/passages?section=${section}`
  );
  console.log("Fetched passages for Level:", section, response.data);
  return response.data.map((item: any) => ({
    Identifier: item.Identifier?.S || "",
    Level: item.Level?.S || "",
    Genre: item.Genre?.S || "",
    Base_Lang_Code: item.Base_Lang_Code?.S || "",
    Base_Lang_Title: item.Base_Lang_Title?.S || "",
    Base_Lang_Description: item.Base_Lang_Description?.S || "",
    Base_Lang_Answer_1: item.Base_Lang_Answer_1?.S || "",
    Base_Lang_Answer_2: item.Base_Lang_Answer_2?.S || "",
    Base_Lang_Answer_3: item.Base_Lang_Answer_3?.S || "",
    Base_Lang_Answer_4: item.Base_Lang_Answer_4?.S || "",
    Base_Lang_Options_1: item.Base_Lang_Options_1?.S || "",
    Base_Lang_Options_2: item.Base_Lang_Options_2?.S || "",
    Base_Lang_Options_3: item.Base_Lang_Options_3?.S || "",
    Base_Lang_Options_4: item.Base_Lang_Options_4?.S || "",
    Base_Lang_Passage: item.Base_Lang_Passage?.S || "",
    Base_Lang_Question_1: item.Base_Lang_Question_1?.S || "",
    Base_Lang_Question_2: item.Base_Lang_Question_2?.S || "",
    Base_Lang_Question_3: item.Base_Lang_Question_3?.S || "",
    Base_Lang_Question_4: item.Base_Lang_Question_4?.S || "",
    Targ_Lang_Code: item.Targ_Lang_Code?.S || "",
    Targ_Lang_Title: item.Targ_Lang_Title?.S || "",
    Targ_Lang_Description: item.Targ_Lang_Description?.S || "",
    Targ_Lang_Answer_1: item.Targ_Lang_Answer_1?.S || "",
    Targ_Lang_Answer_2: item.Targ_Lang_Answer_2?.S || "",
    Targ_Lang_Answer_3: item.Targ_Lang_Answer_3?.S || "",
    Targ_Lang_Answer_4: item.Targ_Lang_Answer_4?.S || "",
    Targ_Lang_Options_1: item.Targ_Lang_Options_1?.S || "",
    Targ_Lang_Options_2: item.Targ_Lang_Options_2?.S || "",
    Targ_Lang_Options_3: item.Targ_Lang_Options_3?.S || "",
    Targ_Lang_Options_4: item.Targ_Lang_Options_4?.S || "",
    Targ_Lang_Passage: item.Targ_Lang_Passage?.S || "",
    Targ_Passage_Word_Timings: item.Targ_Passage_Word_Timings?.S || "",
    Targ_Passage_Audio_URL: item.Targ_Passage_Audio_URL?.S || "",
    Targ_Lang_Question_1: item.Targ_Lang_Question_1?.S || "",
    Targ_Lang_Question_2: item.Targ_Lang_Question_2?.S || "",
    Targ_Lang_Question_3: item.Targ_Lang_Question_3?.S || "",
    Targ_Lang_Question_4: item.Targ_Lang_Question_4?.S || "",
    ImageURL: item.ImageURL?.S || "",
    Prompt: item.Prompt?.S || "",
    Section: item.Section?.S || "",
  }));
};

export const uploadReadingImage = async (
  fileName: string,
  fileType: string
): Promise<string> => {
  const response = await axios.post(`${dataLoaderUri}/upload-reading-image`, {
    fileName,
    fileType,
  });
  return response.data.url;
};

export const addPassage = async (data: PassageData): Promise<any> => {
  try {
    const response = await axios.post(
      `${dataLoaderUri}/api/v1/add-passage`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data; // Return response for potential use
  } catch (error: any) {
    console.error("Error adding passage:", error);
    throw error; // Re-throw to handle in the caller
  }
};

export const updatePassage = async (
  identifier: string,
  level: string,
  data: any
): Promise<any> => {
  return axios.put(
    `${dataLoaderUri}/api/v1/update-passage/${identifier}/${level}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const deletePassage = async (
  identifier: string,
  level: string
): Promise<any> => {
  return axios.delete(
    `${dataLoaderUri}/api/v1/delete-passage/${identifier}/${level}`,
    {
      data: {
        Identifier: identifier,
        Level: level,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export async function uploadImage(
  fileName: string,
  file: Blob
): Promise<string> {
  // Sanitize the file name inside the uploadImage function
  const sanitizeFileName = (fileName: string) => {
    return fileName.replace(/[^a-zA-Z0-9-_]/g, "_");
  };

  try {
    console.log("Uploading image...");

    // Sanitize the file name before uploading
    const sanitizedFileName = sanitizeFileName(fileName);

    // Convert the file to base64
    const base64Image = await convertToBase64(file);
    const body = {
      fileName: sanitizedFileName, // Use sanitized file name
      image: base64Image,
    };

    // Make the POST request to upload the image
    const response = await axios.post<{ url: string }>(
      `${dataLoaderUri}/api/v1/upload-vocab-image`,
      body,
      {
        headers: {
          "Content-Type": "application/json", // Set content type to JSON
        },
      }
    );

    // Return the presigned URL from the API response
    return response.data.url;
  } catch (error) {
    console.log("Error uploading image:", error);
    throw error;
  }
}

// Helper function to convert file to base64
const convertToBase64 = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const result = reader.result as string;
      const base64String = result.split(",")[1];
      resolve(base64String); // Return the base64 string without the prefix
    };

    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

// Function to call the OpenAI API through your backend
// Function to call the OpenAI API through your backend
export const callOpenAI = async (payload: {
  messages: { role: string; content: string }[];
  temperature: number;
  max_tokens: number;
  json_mode?: boolean;
}) => {
  try {
    // Replace with your new backend endpoint URL
    const backendEndpoint =
      "https://4h3ddskfpb.execute-api.us-east-1.amazonaws.com/api/v1/genai";

    // Sending the payload directly to your backend
    const response = await axios.post(backendEndpoint, payload);

    // Assuming the response format provided in the question
    const latestResponse = response.data.response;
    const cost = response.data.cost;
    const inputTokens = response.data.input_tokens;
    const outputTokens = response.data.output_tokens;
    const totalTokens = response.data.total_tokens;
    const chatTurns = response.data.chat_turns;

    // Logging or processing the response fields if needed
    console.log("OpenAI Response:", latestResponse);

    return {
      response: latestResponse,
      cost,
      inputTokens,
      outputTokens,
      totalTokens,
      chatTurns,
    };
  } catch (error) {
    console.log("Error calling backend for OpenAI:", error);
    return null;
  }
};

export const fetchTriviaData = async (
  section: string
): Promise<TriviaData[]> => {
  const uri = `${mobileAppUri}/api/v1/get-trivia?section=${section}`;
  try {
    const response = await axios.get(uri);

    // Properly map the nested response fields
    return response.data.map((item: any) => ({
      Identifier: item.identifier?.S ?? "", // Access 'S' for string values
      Level: item.level?.S ?? "",
      Section: section, // Section is passed as a parameter
      Question: item.question?.S ?? "",
      Options: item.options?.L?.map((option: { S: string }) => option.S) ?? [], // Map 'L' array to extract 'S'
      Answer: item.answer?.S ?? "",
      ImageUrl: item.imageUrl?.S ?? "",
    }));
  } catch (error: any) {
    console.error("Error fetching trivia data:", error.message);
    throw new Error(`Failed to fetch trivia: ${error.message}`);
  }
};

// Add trivia
export const addTrivia = async (data: TriviaData): Promise<void> => {
  const uri = `${dataLoaderUri}/api/v1/add-trivia`;
  try {
    await axios.post(uri, {
      identifier: data.Identifier,
      level: data.Level,
      question: data.Question,
      options: data.Options,
      imageUrl: data.ImageUrl,
      answer: data.Answer,
    });
  } catch (error: any) {
    console.error("Error adding trivia:", error.message);
    throw new Error(`Failed to add trivia: ${error.message}`);
  }
};

// Edit trivia
export const updateTrivia = async (
  identifier: string,
  level: string,
  data: TriviaData
): Promise<void> => {
  const uri = `${dataLoaderUri}/api/v1/edit-trivia`;
  try {
    await axios.put(uri, {
      identifier,
      level,
      question: data.Question,
      options: data.Options,
      answer: data.Answer,
    });
  } catch (error: any) {
    console.error("Error updating trivia:", error.message);
    throw new Error(`Failed to update trivia: ${error.message}`);
  }
};

// Delete trivia
export const deleteTrivia = async (
  identifier: string,
  level: string
): Promise<void> => {
  const uri = `${dataLoaderUri}/api/v1/delete-trivia`;
  try {
    await axios.delete(uri, {
      data: { identifier, level },
    });
  } catch (error: any) {
    console.error("Error deleting trivia:", error.message);
    throw new Error(`Failed to delete trivia: ${error.message}`);
  }
};

export const generateImage = async (
  payload: object
): Promise<string | null> => {
  const IMAGE_GEN_ENDPOINT =
    "https://4h3ddskfpb.execute-api.us-east-1.amazonaws.com/api/v1/imageGen";
  try {
    const response = await axios.post(IMAGE_GEN_ENDPOINT, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data.url; // assuming the API returns { url: "..." }
  } catch (error) {
    console.error("Error generating image:", error);
    return null;
  }
};

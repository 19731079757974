import React, { useState, useCallback, FormEvent, useEffect } from "react";
import {
  buildGenAiPayload,
  buildImageDescriptionPrompt,
  buildGenAiInitialImagePromptPayload,
  buildGenAiImagePayload,
} from "../GenAIPrompts/GenAIPrompts";
import styles from "./Vocabulary.module.css";
import { callOpenAI, generateImage } from "../API/API";
import {
  fetchSections,
  fetchVocabularyWords,
  addVocabulary,
} from "../API/Vocabulary/VocabularyAPI";
import { VocabularyData } from "../API/types";
import { v4 as uuidv4 } from "uuid";

const safeParseOptions = (optionsStr: string): string[] => {
  if (!optionsStr) return [];
  try {
    const parsed = JSON.parse(optionsStr);
    if (Array.isArray(parsed)) {
      return parsed;
    }
    return optionsStr.split(/,\s*/);
  } catch (error) {
    return optionsStr.split(/,\s*/);
  }
};

const AVAILABLE_LANGUAGES = [
  "English",
  "Spanish",
  "Italian",
  "French",
  "German",
  "Portuguese",
];

interface AiData {
  SourceWord: { Correct: string; Options: string[] };
  Translations: { [lang: string]: { Correct: string; Options: string[] } };
  imageUrl?: string;
  customPrompt?: string;
  imageDescription?: string;
  isRegenerating?: boolean;
  showDescription?: boolean;
  imageError?: boolean;
}

interface GroupedVocabulary {
  Base_Word: string;
  translations: VocabularyData[];
  ImageURL: string;
}

const VocabularyGenerator: React.FC = () => {
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [section, setSection] = useState<string>("");
  const [sections, setSections] = useState<string[]>([]);
  const [newWord, setNewWord] = useState("");
  const [wordList, setWordList] = useState<string[]>([]);
  const [generatedData, setGeneratedData] = useState<AiData[]>([]);
  const [existingWords, setExistingWords] = useState<GroupedVocabulary[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    async function loadSections() {
      if (!sourceLanguage) {
        setSections([]);
        setSection("");
        setExistingWords([]);
        return;
      }
      const langCodeMap: { [key: string]: string } = {
        English: "EN",
        Spanish: "ES",
        Italian: "IT",
        French: "FR",
        German: "DE",
        Portuguese: "PT",
      };
      const sectionsData = await fetchSections(langCodeMap[sourceLanguage]);
      setSections(sectionsData);
      if (sectionsData.length > 0) {
        setSection(sectionsData[0]);
      } else {
        setSection("");
        setExistingWords([]);
      }
      console.log(`Sections loaded for ${sourceLanguage}:`, sectionsData);
    }
    loadSections();
  }, [sourceLanguage]);

  useEffect(() => {
    async function loadExistingWords() {
      if (!section) {
        setExistingWords([]);
        return;
      }
      const words = await fetchVocabularyWords(section);
      console.log("Fetched vocabulary words:", words);

      const grouped = words.reduce(
        (acc: { [key: string]: GroupedVocabulary }, word) => {
          if (!acc[word.Targ_Word]) {
            acc[word.Targ_Word] = {
              Base_Word: word.Targ_Word,
              translations: [],
              ImageURL: word.ImageURL,
            };
          }
          acc[word.Targ_Word].translations.push(word);
          return acc;
        },
        {}
      );

      setExistingWords(Object.values(grouped));
    }
    loadExistingWords();
  }, [section]);

  const handleImagePromptChange = (index: number, newPrompt: string) => {
    setGeneratedData((prev) => {
      const newData = [...prev];
      newData[index] = { ...newData[index], customPrompt: newPrompt };
      return newData;
    });
  };

  const generateImageForVocabulary = async (
    word: string,
    index: number,
    prompt?: string
  ) => {
    try {
      let imagePayload;
      let descriptionText = "";
      if (prompt && prompt.trim().length > 0) {
        imagePayload = buildGenAiImagePayload(word, prompt, sourceLanguage);
        descriptionText = prompt;
      } else {
        const descriptionPayload = buildImageDescriptionPrompt(
          sourceLanguage,
          word
        );
        const descriptionResult = await callOpenAI(descriptionPayload);
        if (!descriptionResult)
          throw new Error("Error generating image description");
        descriptionText = descriptionResult.response.trim();
        imagePayload = buildGenAiInitialImagePromptPayload(descriptionText);
      }
      const imageUrl = await generateImage(imagePayload);
      if (!imageUrl) throw new Error("Image generation failed");
      setGeneratedData((prev) => {
        const newData = [...prev];
        newData[index] = {
          ...newData[index],
          imageUrl,
          imageDescription: descriptionText,
          showDescription: false,
          imageError: false,
        };
        return newData;
      });
    } catch (err: any) {
      console.error(`Error generating image for word "${word}":`, err);
      setGeneratedData((prev) => {
        const newData = [...prev];
        newData[index] = {
          ...newData[index],
          imageError: true,
        };
        return newData;
      });
    }
  };

  const generateInitialImages = async (data: AiData[], startIndex: number) => {
    await Promise.all(
      data
        .slice(startIndex)
        .map((item, idx) =>
          generateImageForVocabulary(item.SourceWord.Correct, startIndex + idx)
        )
    );
  };

  const handleAddWord = (e: FormEvent) => {
    e.preventDefault();
    if (!newWord.trim()) return;
    setWordList((prev) => [...prev, newWord.trim()]);
    setNewWord("");
  };

  const removeWord = (index: number) => {
    setWordList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleGenerate = useCallback(async () => {
    if (wordList.length === 0) {
      alert("Please enter at least one word first.");
      return;
    }
    setIsGenerating(true);
    try {
      const newResults: AiData[] = [];

      for (const word of wordList) {
        const payload = buildGenAiPayload(sourceLanguage, [word]);
        const result = await callOpenAI(payload);
        if (!result)
          throw new Error(`API error generating translations for "${word}"`);

        console.log(`callOpenAI raw response for "${word}":`, result.response);

        let jsonString = result.response || "";
        const startIndex = jsonString.indexOf("[");
        const endIndex = jsonString.lastIndexOf("]") + 1;

        let data: AiData[];
        if (startIndex !== -1 && endIndex > startIndex) {
          jsonString = jsonString.slice(startIndex, endIndex).trim();
          try {
            data = JSON.parse(jsonString);
            if (!Array.isArray(data))
              throw new Error("Parsed response is not an array");
          } catch (parseError) {
            console.error(
              `Failed to parse JSON for "${word}":`,
              parseError,
              "Raw string:",
              jsonString
            );
            throw new Error("Response is not valid JSON");
          }
        } else {
          console.warn(
            `Response for "${word}" is not a JSON array, attempting fallback:`,
            jsonString
          );
          if (jsonString.includes(",")) {
            const words = jsonString.split(",").map((w: string) => w.trim());
            data = words.map(
              (w: string) =>
                ({
                  SourceWord: { Correct: w, Options: [w, "a", "b", "c"] },
                  Translations: {
                    [sourceLanguage]: {
                      Correct: w,
                      Options: [w, "x", "y", "z"],
                    },
                  },
                } as AiData)
            );
          } else {
            data = [
              {
                SourceWord: {
                  Correct: jsonString.trim(),
                  Options: [jsonString.trim(), "a", "b", "c"],
                },
                Translations: {
                  [sourceLanguage]: {
                    Correct: jsonString.trim(),
                    Options: [jsonString.trim(), "x", "y", "z"],
                  },
                },
              } as AiData,
            ];
          }
        }

        const item = data[0];
        if (!item) throw new Error(`No data returned for "${word}"`);

        const filteredTranslations = { ...item.Translations };
        delete filteredTranslations[sourceLanguage];
        newResults.push({ ...item, Translations: filteredTranslations });
      }

      setGeneratedData((prev) => {
        const combinedData = [...prev, ...newResults];
        generateInitialImages(combinedData, prev.length);
        return combinedData;
      });

      setWordList([]);
    } catch (err: any) {
      console.error("Error generating data:", err);
    } finally {
      setIsGenerating(false);
    }
  }, [sourceLanguage, wordList]);

  const handleGenerateImage = async (index: number) => {
    const word = generatedData[index].SourceWord.Correct;
    const promptText = generatedData[index].customPrompt;
    setGeneratedData((prev) => {
      const newData = [...prev];
      newData[index] = { ...newData[index], isRegenerating: true };
      return newData;
    });
    await generateImageForVocabulary(word, index, promptText);
    setGeneratedData((prev) => {
      const newData = [...prev];
      newData[index] = { ...newData[index], isRegenerating: false };
      return newData;
    });
  };

  const handleToggleDescription = (index: number) => {
    setGeneratedData((prev) => {
      const newData = [...prev];
      newData[index] = {
        ...newData[index],
        showDescription: !newData[index].showDescription,
      };
      return newData;
    });
  };

  const handleSave = async () => {
    if (generatedData.length === 0) {
      return;
    }
    setIsGenerating(true);
    try {
      const langCodeMap: { [key: string]: string } = {
        English: "EN",
        Spanish: "ES",
        Italian: "IT",
        French: "FR",
        German: "DE",
        Portuguese: "PT",
      };
      const sourceLangCode = langCodeMap[sourceLanguage];
      const targetLanguages = [
        "English",
        "Spanish",
        "Italian",
        "French",
        "German",
        "Portuguese",
      ];

      // Collect the words being saved
      const newlySavedWords = generatedData.map(
        (item) => item.SourceWord.Correct
      );

      for (const item of generatedData) {
        for (const targetLang of targetLanguages) {
          if (targetLang === sourceLanguage) continue;

          const vocabData: VocabularyData = {
            Identifier: uuidv4(),
            Level: section,
            Base_Word: item.Translations[targetLang]?.Correct || "",
            Base_Lang_Code: langCodeMap[targetLang],
            Base_Lang_Options: JSON.stringify(
              item.Translations[targetLang]?.Options || []
            ),
            Targ_Word: item.SourceWord.Correct,
            Targ_Lang_Code: sourceLangCode,
            Targ_Lang_Options: JSON.stringify(item.SourceWord.Options),
            Explanation_Word_Timing: "",
            Phonetic_Transcription: "",
            Pronunciation_Explanation: "",
            Pronunciation_Explanation_Audio: "",
            Targ_Syllable: "",
            Targ_Syllable_Sounds: "",
            Word_Audio: "",
            ImageURL: item.imageUrl || "",
          };
          await addVocabulary(vocabData);
        }
      }

      const fetchedWords = await fetchVocabularyWords(section);
      const grouped = fetchedWords.reduce(
        (acc: { [key: string]: GroupedVocabulary }, word) => {
          if (!acc[word.Targ_Word]) {
            acc[word.Targ_Word] = {
              Base_Word: word.Targ_Word,
              translations: [],
              ImageURL: word.ImageURL,
            };
          }
          acc[word.Targ_Word].translations.push(word);
          return acc;
        },
        {}
      );

      // Convert to array and sort: newly saved words at the top
      const groupedArray = Object.values(grouped);
      const sortedExistingWords = groupedArray.sort((a, b) => {
        const aIsNew = newlySavedWords.includes(a.Base_Word);
        const bIsNew = newlySavedWords.includes(b.Base_Word);
        if (aIsNew && !bIsNew) return -1; // a is new, b is old
        if (!aIsNew && bIsNew) return 1; // b is new, a is old
        if (aIsNew && bIsNew) {
          // Both are new, preserve order from generatedData
          return (
            newlySavedWords.indexOf(a.Base_Word) -
            newlySavedWords.indexOf(b.Base_Word)
          );
        }
        return 0; // Both are old, maintain original order
      });

      setExistingWords(sortedExistingWords);
    } catch (err: any) {
      console.error("Error saving vocabulary:", err);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDeleteRow = (idx: number) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this word?"
    );
    if (!confirmed) return;
    setGeneratedData((prev) => prev.filter((_, i) => i !== idx));
  };

  const handleClearGenerated = () => {
    const confirmed = window.confirm(
      "Are you sure you want to clear all generated vocabulary?"
    );
    if (!confirmed) return;
    setGeneratedData([]);
  };

  const TranslationCarousel: React.FC<{ translations: VocabularyData[] }> = ({
    translations,
  }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextTranslation = () => {
      setCurrentIndex((prev) => (prev + 1) % translations.length);
    };

    const prevTranslation = () => {
      setCurrentIndex(
        (prev) => (prev - 1 + translations.length) % translations.length
      );
    };

    const currentTranslation = translations[currentIndex];

    return (
      <div className={styles.carousel}>
        <button onClick={prevTranslation} className={styles.carouselButton}>
          ←
        </button>
        <div>
          <strong>
            {currentTranslation.Base_Word} ({currentTranslation.Base_Lang_Code})
          </strong>
          <br />
          Options:{" "}
          {safeParseOptions(currentTranslation.Base_Lang_Options).join(", ")}
        </div>
        <button onClick={nextTranslation} className={styles.carouselButton}>
          →
        </button>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Vocabulary Generator</h1>

      <div className={styles.formRow}>
        <label className={styles.label}>
          <strong>Choose Language:</strong>
          <select
            value={sourceLanguage}
            onChange={(e) => setSourceLanguage(e.target.value)}
            className={styles.input}
          >
            <option value="">Select a language</option>
            {AVAILABLE_LANGUAGES.map((lang) => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            ))}
          </select>
        </label>
        <label className={styles.label}>
          <strong>Choose Section:</strong>
          <select
            value={section}
            onChange={(e) => setSection(e.target.value)}
            className={styles.input}
            disabled={!sourceLanguage}
          >
            {sections.length === 0 ? (
              <option value="">No sections available</option>
            ) : (
              sections.map((sec, idx) => (
                <option key={idx} value={sec}>
                  {sec}
                </option>
              ))
            )}
          </select>
        </label>
      </div>

      <form onSubmit={handleAddWord} className={styles.form}>
        <input
          type="text"
          placeholder="Type a word and press Enter"
          value={newWord}
          onChange={(e) => setNewWord(e.target.value)}
          className={styles.input}
        />
        <button type="submit" className={styles.button}>
          Add Word
        </button>
        <div className={styles.wordListContainer}>
          {wordList.map((word, idx) => (
            <span key={idx} className={styles.wordItem}>
              {word}
              <button
                onClick={() => removeWord(idx)}
                className={styles.xButton}
              >
                ×
              </button>
            </span>
          ))}
        </div>
        <button
          onClick={handleGenerate}
          disabled={isGenerating || wordList.length === 0}
          className={styles.button}
        >
          {isGenerating ? "Generating..." : "Generate Translations"}
        </button>
      </form>

      {generatedData.length > 0 && (
        <div>
          <h2>Generated Vocabulary</h2>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Source Word</th>
                <th>Translations</th>
                <th>Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {generatedData.map((item, idx) => (
                <tr key={idx}>
                  <td>
                    <strong>{item.SourceWord.Correct}</strong>
                    <br />
                    Options: {item.SourceWord.Options.join(", ")}
                  </td>
                  <td>
                    {Object.entries(item.Translations).map(
                      ([lang, dataObj]) => (
                        <div key={lang}>
                          <strong>{lang}</strong>: {dataObj.Correct}
                          <br />
                          Options: {dataObj.Options.join(", ")}
                        </div>
                      )
                    )}
                  </td>
                  <td className={styles.imageCell}>
                    {item.imageUrl ? (
                      <img
                        src={item.imageUrl}
                        alt="Generated"
                        className={styles.image}
                      />
                    ) : item.imageError ? (
                      <p style={{ color: "red" }}>Please regenerate!</p>
                    ) : (
                      <p>Generating image...</p>
                    )}
                    {item.imageDescription && (
                      <button
                        onClick={() => handleToggleDescription(idx)}
                        className={styles.button}
                      >
                        {item.showDescription ? "Hide prompt" : "Show prompt"}
                      </button>
                    )}
                    {item.imageDescription && item.showDescription && (
                      <p>{item.imageDescription}</p>
                    )}
                    <input
                      type="text"
                      value={item.customPrompt || ""}
                      placeholder="Enter prompt (e.g., A cartoonish, semi-realistic ...)"
                      onChange={(e) =>
                        handleImagePromptChange(idx, e.target.value)
                      }
                      className={`${styles.input} ${styles.imageInput}`}
                    />
                    <button
                      onClick={() => handleGenerateImage(idx)}
                      className={styles.button}
                      disabled={item.isRegenerating}
                    >
                      {item.isRegenerating ? "Regenerating..." : "Regenerate"}
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => handleDeleteRow(idx)}
                      className={styles.xButton}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.buttonContainer}>
            <button
              onClick={handleSave}
              className={styles.saveButton}
              disabled={isGenerating}
            >
              {isGenerating ? "Saving..." : "Save"}
            </button>
            <button
              onClick={handleClearGenerated}
              className={styles.clearButton}
              disabled={isGenerating}
            >
              Clear Generated Vocabulary
            </button>
          </div>
        </div>
      )}

      {existingWords.length > 0 && (
        <div>
          <h2>Existing Vocabulary</h2>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Base Word</th>
                <th>Target Word</th>
                <th>Image</th>
              </tr>
            </thead>
            <tbody>
              {existingWords.map((group, idx) => (
                <tr key={idx}>
                  <td>
                    <TranslationCarousel translations={group.translations} />
                  </td>
                  <td>
                    <strong>
                      {group.Base_Word} ({group.translations[0].Targ_Lang_Code})
                    </strong>
                    <br />
                    Options:{" "}
                    {safeParseOptions(
                      group.translations[0].Targ_Lang_Options
                    ).join(", ")}
                  </td>
                  <td className={styles.imageCell}>
                    {group.ImageURL ? (
                      <img
                        src={group.ImageURL}
                        alt="Existing Vocabulary"
                        className={styles.image}
                      />
                    ) : (
                      <p>No Image</p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default VocabularyGenerator;

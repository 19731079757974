import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./Homepage/Homepage";
import TableDetails from "./Passages/Passages";
import VocabularyDetails from "./Vocabulary/Vocabulary";
import Course from "./Course/Course";
import Lesson from "./Lesson/Lesson_New";
import ChatterboxMetrics from "./Metrics/Metrics";
import TriviaDetails from "./Trivia/Trivia";
import CourseMetrics from "./Metrics/CourseMetrics";
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/passages" element={<TableDetails />} />
      <Route path="/vocabulary" element={<VocabularyDetails />} />
      <Route path="/course" element={<Course />} />
      <Route path="/Lessons" element={<Lesson />} />
      <Route path="/metrics" element={<ChatterboxMetrics />} />
      <Route path="/metrics/course" element={<CourseMetrics />} />
      <Route path="/trivia" element={<TriviaDetails />} />
    </Routes>
  );
};

export default App;

// SentenceTranslationGenerator.tsx
import React, { useState } from "react";
import {
  buildSentencePayload,
  buildSpanishTranslationPayload,
  buildGermanTranslationPayload,
  buildItalianTranslationPayload,
  buildFrenchTranslationPayload,
  buildPortugueseTranslationPayload,
} from "../GenAIPrompts/GenAIPrompts";
import "./SentenceTranslation.css";
import { callOpenAI } from "../API/API";

// Type definitions for our data structures.
interface SentenceData {
  sentence: string;
  correctWords: string[];
  distractorWords: string[];
}

interface TranslationRow {
  language: string;
  sentences: SentenceData[];
}

const SentenceTranslationGenerator: React.FC = () => {
  // State variables for form selections.
  const [section, setSection] = useState<string>("Greetings");
  const [numSentences, setNumSentences] = useState<number>(1);

  // State for the generated sentences and translations.
  const [sentences, setSentences] = useState<SentenceData[]>([]);
  const [translations, setTranslations] = useState<TranslationRow[]>([]);

  // Loading and error states.
  const [loadingSentences, setLoadingSentences] = useState<boolean>(false);
  const [loadingTranslations, setLoadingTranslations] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Generate sentences using callOpenAI.
  const handleGenerateSentences = async () => {
    setLoadingSentences(true);
    setError(null);
    try {
      const payload = buildSentencePayload(section, numSentences);
      const result = await callOpenAI(payload);
      if (!result) {
        throw new Error("Error generating sentences");
      }
      console.log("API response for sentences:", result);
      // Parse the returned response (assumed to be a JSON string)
      const parsedResponse = JSON.parse(result.response);
      console.log("Parsed response:", parsedResponse);
      setSentences(parsedResponse.sentences || []);
      // Clear translations since new sentences have been generated.
      setTranslations([]);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoadingSentences(false);
    }
  };

  // Regenerate a single sentence by index.
  const handleRegenerateSentence = async (index: number) => {
    setLoadingSentences(true);
    setError(null);
    try {
      // Generate one sentence only.
      const payload = buildSentencePayload(section, 1);
      const result = await callOpenAI(payload);
      if (!result) {
        throw new Error("Error regenerating sentence");
      }
      console.log("API response for regenerate sentence:", result);
      const parsedResponse = JSON.parse(result.response);
      console.log("Parsed response for regenerate sentence:", parsedResponse);
      const newSentence: SentenceData = parsedResponse.sentences[0];
      // Replace the sentence at the specified index.
      setSentences((prev) =>
        prev.map((sent, idx) => (idx === index ? newSentence : sent))
      );
      // Clear translations because sentences have changed.
      setTranslations([]);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoadingSentences(false);
    }
  };

  // Generate translations for the generated sentences.
  const handleGenerateTranslations = async () => {
    setLoadingTranslations(true);
    setError(null);
    try {
      // Prepare the original data from generated sentences.
      const originalData = JSON.stringify({ sentences });

      // Prepare payloads for each target language.
      const payloads = [
        {
          language: "Spanish",
          payload: buildSpanishTranslationPayload(originalData),
        },
        {
          language: "German",
          payload: buildGermanTranslationPayload(originalData),
        },
        {
          language: "Italian",
          payload: buildItalianTranslationPayload(originalData),
        },
        {
          language: "French",
          payload: buildFrenchTranslationPayload(originalData),
        },
        {
          language: "Portuguese",
          payload: buildPortugueseTranslationPayload(originalData),
        },
      ];

      // Concurrently call the translation API for each payload using callOpenAI.
      const translationPromises = payloads.map(async (item) => {
        try {
          const result = await callOpenAI(item.payload);
          if (!result) {
            throw new Error(
              `Error generating ${item.language} translation`
            );
          }
          console.log(`API response for ${item.language}:`, result);
          const parsedTranslationResponse = JSON.parse(result.response);
          // Extract the translation row (assuming the response format follows our prompt)
          const translationRow = parsedTranslationResponse.translations[0];
          return translationRow;
        } catch (err: any) {
          console.error(
            `Error generating translation for ${item.language}:`,
            err
          );
          // Return a translation row with empty sentences if the API call fails.
          return {
            language: item.language,
            sentences: [],
          };
        }
      });

      // Await all translation API calls concurrently.
      const translationResults = await Promise.all(translationPromises);

      // Build the English translation row from the generated sentences.
      const englishRow: TranslationRow = {
        language: "English",
        sentences: sentences,
      };

      // Combine the English row with the translation results.
      const combinedTranslations = [englishRow, ...translationResults];

      setTranslations(combinedTranslations);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoadingTranslations(false);
    }
  };

  // Delete a translation row from the table.
  const handleDeleteTranslationRow = (rowIndex: number) => {
    setTranslations((prev) => prev.filter((_, idx) => idx !== rowIndex));
  };

  // Save function (could be adapted to your saving logic).
  const handleSave = () => {
    console.log("Saved data", { sentences, translations });
    alert("Data saved!");
  };

  return (
    <div className="container mainContainer">
      <h1 className="title">Sentence Translation Generator</h1>
      {error && <p className="error">{error}</p>}

      {/* Section and Sentence Count Selection */}
      <div className="formRow">
        <label className="label">
          Section:
          <select
            className="input"
            value={section}
            onChange={(e) => setSection(e.target.value)}
          >
            <option value="Greetings">Greetings</option>
            <option value="Useful Phrases">Useful Phrases</option>
            <option value="Useful Phrases: Part 2">Useful Phrases: Part 2</option>
            <option value="Masculine vs Feminine">Masculine vs Feminine</option>
            <option value="Plural vs Singular">Plural vs Singular</option>
            <option value="prepositions">prepositions</option>
            <option value="Regular Verbs">Regular Verbs</option>
            <option value="Interrogatives">Interrogatives</option>
            <option value="To be (Permanent)">To be (Permanent)</option>
            <option value="To be (Temporary)">To be (Temporary)</option>
            <option value="Present Progressive – START LEVEL 3">
              Present Progressive – START LEVEL 3
            </option>
            <option value="Transportation">Transportation</option>
            <option value="Colors">Colors</option>
            <option value="Adjectives">Adjectives</option>
            <option value="Possessive Pronouns">Possessive Pronouns</option>
            <option value="Possessive Adjective">Possessive Adjective</option>
            <option value="To want">To want</option>
            <option value="Food & Drink 1">Food & Drink 1</option>
            <option value="To want Part two">To want Part two</option>
            <option value="(4) - to go  – START LEVEL 4">
              (4) - to go – START LEVEL 4
            </option>
            <option value="Going to">Going to</option>
            <option value="To have">To have</option>
            <option value="To Have to">To Have to</option>
            <option value="Numbers 1-10">Numbers 1-10</option>
            <option value="Numbers 11-100">Numbers 11-100</option>
            <option value="Clothing">Clothing</option>
            <option value="(5) Emotions – START LEVEL 5">
              (5) Emotions – START LEVEL 5
            </option>
            <option value="Common Items">Common Items</option>
            <option value="To be able to">To be able to</option>
            <option value="Opinions">Opinions</option>
            <option value="Better & Worse">Better & Worse</option>
            <option value="Thinking">Thinking</option>
            <option value="More adjective">More adjective</option>
            <option value="Weather & Nature">Weather & Nature</option>
            <option value="Household items & Furniture">
              Household items & Furniture
            </option>
            <option value="Entertainment & Free time">
              Entertainment & Free time
            </option>
            <option value="here and there">here and there</option>
            <option value="Some more verbs  1">Some more verbs 1</option>
            <option value="(6) To know – START LEVL 6">
              (6) To know – START LEVL 6
            </option>
            <option value="Clothes">Clothes</option>
            <option value="Commands & Requests">Commands & Requests</option>
            <option value="Body parts">Body parts</option>
            <option value="Hobbies">Hobbies</option>
            <option value="Frequencies">Frequencies</option>
            <option value="Technology">Technology</option>
            <option value="(7) Present perfect"> (7) Present perfect</option>
            <option value="Past participle">Past participle</option>
            <option value="Present_Perfect_Progressive">
              Present_Perfect_Progressive
            </option>
            <option value="Days – START LEVEL 7">Days – START LEVEL 7</option>
            <option value="More emotions">More emotions</option>
            <option value="Past tense preterite">Past tense preterite</option>
            <option value="More Preterite -">More Preterite -</option>
            <option value="Some More Preterite">Some More Preterite</option>
            <option value="Even More Preterite">Even More Preterite</option>
            <option value="Conditional">Conditional</option>
            <option value="Story telling (More_Imperfect)">
              Story telling (More_Imperfect)
            </option>
            <option value="Past tense - Imperfect (More_Imperfect)">
              Past tense - Imperfect (More_Imperfect)
            </option>
            <option value="More Imperfect (Some_More_Imperfect)">
              More Imperfect (Some_More_Imperfect)
            </option>
            <option value="Some More Imperfect (Months)">
              Some More Imperfect (Months)
            </option>
            <option value="Even More Imperfect (Cooking_&_Kitchen)">
              Even More Imperfect (Cooking_&_Kitchen)
            </option>
            <option value="Cooking & Kitchen (Rooms) – START LEVEL 7">
              Cooking & Kitchen (Rooms) – START LEVEL 7
            </option>
            <option value="Holidays and Celebrations (Household_Items)">
              Holidays and Celebrations (Household_Items)
            </option>
            <option value="Formal (Celebrations)">Formal (Celebrations)</option>
            <option value="Professions (Formal)">Professions (Formal)</option>
            <option value="Reflexive verbs (Professions)">
              Reflexive verbs (Professions)
            </option>
            <option value="Shopping & Money (Reflexive_Verbs)">
              Shopping & Money (Reflexive_Verbs)
            </option>
            <option value="School (To_Like)">School (To_Like)</option>
            <option value="Daily Routine (Shopping_&_Money)">
              Daily Routine (Shopping_&_Money)
            </option>
            <option value="Verbs… Again (School)">Verbs… Again (School)</option>
            <option value="Conditional mood - I would like (Daily_Routine)">
              Conditional mood - I would like (Daily_Routine)
            </option>
            <option value="Body parts (Verbs_Again)">
              Body parts (Verbs_Again)
            </option>
            <option value="Physical descriptions (Conditional_Mood)">
              Physical descriptions (Conditional_Mood)
            </option>
            <option value="Sports and fitness (More_Descriptions)">
              Sports and fitness (More_Descriptions)
            </option>
            <option value="Por vs para (Physical_Descriptions)">
              Por vs para (Physical_Descriptions)
            </option>
            <option value="More foods (sports and fitness)">
              More foods (sports and fitness)
            </option>
            <option value="Verbs pt.4 (por vs para)">
              Verbs pt.4 (por vs para)
            </option>
            <option value="Nature">Nature</option>
            <option value="Society">Society</option>
            <option value="Future tense">Future tense</option>
            <option value="Future tense practice">Future tense practice</option>
            <option value="More future practice">More future practice</option>
            <option value="Conditional">Conditional</option>
            <option value="Storytelling">Storytelling</option>
            <option value="More Food">More Food</option>
            <option value="More verbs">More verbs</option>
            <option value="Daily activites">Daily activites</option>
            <option value="Household_Items">Household_Items</option>
            <option value="More_Verbs">More_Verbs</option>
          </select>
        </label>
        <label className="label">
          No. of Sentences:
          <select
            className="input"
            value={numSentences}
            onChange={(e) => setNumSentences(Number(e.target.value))}
          >
            {[1, 2, 3, 4, 5].map((n) => (
              <option key={n} value={n}>
                {n}
              </option>
            ))}
          </select>
        </label>
      </div>

      {/* Generate Sentences Button */}
      <div className="buttonContainer">
        <button
          className="button"
          onClick={handleGenerateSentences}
          disabled={loadingSentences}
        >
          {loadingSentences ? "Generating Sentences..." : "Generate Sentences"}
        </button>
      </div>

      {/* Display Generated Sentences */}
      {sentences.length > 0 && (
        <div>
          <h2>Generated Sentences</h2>
          {sentences.map((sent, index) => (
            <div key={index} className="sentenceCard">
              <p>
                <strong>Sentence:</strong> {sent.sentence}
              </p>
              <p>
                <strong>Correct Words:</strong> {sent.correctWords.join(", ")}
              </p>
              <p>
                <strong>Distractor Words:</strong> {sent.distractorWords.join(", ")}
              </p>
              <button
                className="button"
                onClick={() => handleRegenerateSentence(index)}
                disabled={loadingSentences}
              >
                Regenerate
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Generate Translations Button */}
      {sentences.length > 0 && (
        <div className="buttonContainer">
          <button
            className="button"
            onClick={handleGenerateTranslations}
            disabled={loadingTranslations}
          >
            {loadingTranslations
              ? "Generating Translations..."
              : "Generate Translations"}
          </button>
        </div>
      )}

      {/* Display Translations Table */}
      {translations.length > 0 && (
        <div>
          <h2>Translations</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Language</th>
                <th>Sentences</th>
                <th>Correct Words</th>
                <th>Distractor Words</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {translations.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{row.language}</td>
                  <td>
                    {row.sentences.map((s, idx) => (
                      <p key={idx}>{s.sentence}</p>
                    ))}
                  </td>
                  <td>
                    {row.sentences.map((s, idx) => (
                      <p key={idx}>{s.correctWords.join(", ")}</p>
                    ))}
                  </td>
                  <td>
                    {row.sentences.map((s, idx) => (
                      <p key={idx}>{s.distractorWords.join(", ")}</p>
                    ))}
                  </td>
                  <td>
                    <button
                      className="button"
                      onClick={() => handleDeleteTranslationRow(rowIndex)}
                    >
                      Delete Row
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Save Button */}
      {(sentences.length > 0 || translations.length > 0) && (
        <div className="saveContainer">
          <button className="saveButton" onClick={handleSave}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default SentenceTranslationGenerator;

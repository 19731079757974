import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface MatchingAnsweredData {
  user_id: string;
  event: string;
  event_detail: string;
  event_detail_2: string;
  event_detail_3: string;
  event_timestamp: string;
  device_id: string;
  section_level: string;
  section: string;
  session_id: string;
  event_type: string;
  event_id: string;
}

// Structure for grouped data
interface GroupedData {
  [section: string]: {
    correct: number;
    incorrect: number;
  };
}

const MatchingAnswered: React.FC = () => {
  const [matchingData, setMatchingData] = useState<MatchingAnsweredData[]>([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from the given MatchingAnswered endpoint
  const fetchMatchingData = async () => {
    try {
      const response = await fetch(
        "https://ob38jihfoj.execute-api.us-east-1.amazonaws.com/dev/api/v1/metric-queries/b0472d0f-d857-4ee3-a826-ca59d70d30e9"
      );
      const apiData = await response.json();

      // Transform the raw API data to our desired structure
      const transformed = transformMatchingData(apiData);
      setMatchingData(transformed);
    } catch (error) {
      console.error("Error fetching matching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Transform the raw data: [ [header...], [ [row], [row], ... ] ]
  const transformMatchingData = (apiData: any[]): MatchingAnsweredData[] => {
    // Make sure apiData[1] exists and has at least 2 rows
    // (since the first item might be an internal header row)
    if (!apiData || !Array.isArray(apiData[1]) || apiData[1].length < 2) {
      return [];
    }

    // Skip the top-level header row (apiData[0]) -> use apiData[1]
    // Then skip the "internal header" row within apiData[1], so we do apiData[1].slice(1)
    return apiData[1].slice(1).map((row: string[]) => ({
      user_id: row[0],
      event: row[1],
      event_detail: row[2],
      event_detail_2: row[3],
      event_detail_3: row[4],
      event_timestamp: row[5],
      device_id: row[6],
      section_level: row[7],
      section: row[8],
      session_id: row[9],
      event_type: row[10],
      event_id: row[11],
    }));
  };

  // Helper function to process matching answered data for the stacked bar chart
  const processMatchingData = () => {
    const groupedData: GroupedData = {};

    matchingData.forEach((event) => {
      const section = event.section || "Unknown Section";
      if (!groupedData[section]) {
        groupedData[section] = { correct: 0, incorrect: 0 };
      }

      // event_type can be "answer_correct" or "answer_incorrect"
      if (event.event_type === "answer_correct") {
        groupedData[section].correct += 1;
      } else if (event.event_type === "answer_incorrect") {
        groupedData[section].incorrect += 1;
      }
    });

    const labels = Object.keys(groupedData);

    return {
      labels,
      correctData: labels.map((section) => groupedData[section].correct),
      incorrectData: labels.map((section) => groupedData[section].incorrect),
      totalData: labels.map(
        (section) =>
          groupedData[section].correct + groupedData[section].incorrect
      ),
    };
  };

  useEffect(() => {
    fetchMatchingData();
  }, []);

  if (loading) {
    return <div>Loading Matching data...</div>;
  }

  // Prepare the chart data
  const { labels, correctData, incorrectData, totalData } =
    processMatchingData();

  const matchingChartData = {
    labels,
    datasets: [
      {
        label: "Correct",
        data: correctData,
        backgroundColor: "#3366cc",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Incorrect",
        data: incorrectData,
        backgroundColor: "#dc3912",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Custom tooltip to show percentage
  const barOptions = {
    plugins: {
      legend: {
        position: "top" as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const datasetLabel = context.dataset.label; // "Correct" or "Incorrect"
            const value = context.parsed.y; // numeric value of this bar
            const dataIndex = context.dataIndex; // index of the label
            const total = totalData[dataIndex] || 0;

            let percentage = 0;
            if (total > 0) {
              percentage = (value / total) * 100;
            }
            const percentageStr = percentage.toFixed(2);

            return `${datasetLabel}: ${value} of ${total} (${percentageStr}%)`;
          },
        },
      },
    },
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };

  return (
    <div>
      <h2>Matching: Correct vs Incorrect</h2>
      <Bar data={matchingChartData} options={barOptions} />
    </div>
  );
};

export default MatchingAnswered;

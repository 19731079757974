import React, {
  useState,
  useCallback,
  FormEvent,
  useRef,
  useEffect,
} from "react";
import {
  buildRandomTopicPrompt,
  buildPassagePrompt,
  buildPassageInitialImagePromptPayload,
  buildPassageCustomImagePayload,
} from "../GenAIPrompts/GenAIPrompts";
import styles from "./Passages.module.css";
import {
  callOpenAI,
  generateImage,
  fetchPassages,
  addPassage,
} from "../API/API";
import { PassageData } from "../API/types"; // Import PassageData
import { v4 as uuidv4 } from "uuid";

// A set of available languages
const AVAILABLE_LANGUAGES = [
  "Spanish",
  "English",
  "Italian",
  "French",
  "German",
  "Portuguese",
];

// Proficiency levels 1..10
const PROFICIENCY_LEVELS = Array.from({ length: 10 }, (_, i) =>
  (i + 1).toString()
);

// Data shape for storing random topics for each genre
interface GenreTopics {
  genre: string;
  topics: string[];
}

// Extend PassageData with local-only fields for generated passages
type GeneratedPassageData = PassageData & {
  customPrompt?: string;
  isRegenerating?: boolean;
};

// Wrapper for generated passages to include topic
interface GeneratedPassage {
  passage: GeneratedPassageData;
  topic: string;
}

const Passages: React.FC = () => {
  const [language, setLanguage] = useState("Spanish");
  const [proficiency, setProficiency] = useState("1");
  const [newGenre, setNewGenre] = useState("");
  const [genres, setGenres] = useState<string[]>([]);
  const [topicsCount, setTopicsCount] = useState<number>(1);
  const [topicsState, setTopicsState] = useState<GenreTopics[]>([]);
  const [passagesState, setPassagesState] = useState<GeneratedPassage[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalData, setEditModalData] =
    useState<GeneratedPassageData | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const imagesGeneratedRef = useRef(false);
  const [section, setSection] = useState<string>("Greetings");
  const [sections, setSections] = useState<string[]>([]);
  const [existingPassages, setExistingPassages] = useState<PassageData[]>([]);

  const handleAddGenre = (e: FormEvent) => {
    e.preventDefault();
    const trimmed = newGenre.trim();
    if (!trimmed) return;
    setGenres((prev) => [...prev, trimmed]);
    setNewGenre("");
  };

  const removeGenre = (index: number) => {
    setGenres((prev) => prev.filter((_, i) => i !== index));
    setTopicsState((prev) => prev.filter((_, i) => i !== index));
  };

  const handleGenerateTopics = useCallback(async () => {
    if (genres.length === 0) {
      alert("Please add at least one genre.");
      return;
    }
    if (topicsCount < 1) {
      alert("Topics/Passages count must be at least 1.");
      return;
    }
    setIsGenerating(true);
    setError(null);

    try {
      const newTopicsState: GenreTopics[] = [];
      for (let genre of genres) {
        const payload = buildRandomTopicPrompt(
          genre,
          section,
          topicsCount,
          language
        );
        const result = await callOpenAI(payload);
        if (!result) throw new Error(`API error for genre "${genre}".`);
        let jsonString = result.response || "";
        const startIndex = jsonString.indexOf("[");
        const endIndex = jsonString.lastIndexOf("]") + 1;
        if (startIndex === -1 || endIndex === 0)
          throw new Error(
            `Could not find a JSON array in the response for genre "${genre}".`
          );
        jsonString = jsonString.slice(startIndex, endIndex).trim();
        const topicsArray = JSON.parse(jsonString);
        if (!Array.isArray(topicsArray))
          throw new Error(
            `Parsed response for genre "${genre}" is not an array.`
          );
        const validatedTopics: string[] = topicsArray.map((t: any) =>
          String(t)
        );
        newTopicsState.push({ genre, topics: validatedTopics });
      }
      setTopicsState(newTopicsState);
      setPassagesState([]);
    } catch (err: any) {
      console.error("Error generating topics:", err);
      setError(err.message);
    } finally {
      setIsGenerating(false);
    }
  }, [genres, topicsCount, language, section]);

  const handleRegenerateTopics = useCallback(
    async (genreIndex: number) => {
      if (!genres[genreIndex]) return;
      if (topicsCount < 1) {
        alert("Topics/Passages count must be at least 1.");
        return;
      }
      setIsGenerating(true);
      setError(null);
      const genre = genres[genreIndex];
      try {
        const payload = buildRandomTopicPrompt(
          genre,
          section,
          topicsCount,
          language
        );
        const result = await callOpenAI(payload);
        if (!result) throw new Error(`API error for genre "${genre}".`);
        let jsonString = result.response || "";
        const startIndex = jsonString.indexOf("[");
        const endIndex = jsonString.lastIndexOf("]") + 1;
        if (startIndex === -1 || endIndex === 0)
          throw new Error(
            `Could not find a JSON array in the response for genre "${genre}".`
          );
        jsonString = jsonString.slice(startIndex, endIndex).trim();
        const topicsArray = JSON.parse(jsonString);
        const validatedTopics: string[] = topicsArray.map((t: any) =>
          String(t)
        );
        setTopicsState((prev) =>
          prev.map((item, idx) =>
            idx === genreIndex ? { ...item, topics: validatedTopics } : item
          )
        );
      } catch (err: any) {
        console.error("Error regenerating topics:", err);
        setError(err.message);
      } finally {
        setIsGenerating(false);
      }
    },
    [genres, topicsCount, language, section]
  );

  const handleGeneratePassages = useCallback(async () => {
    if (topicsState.length === 0) {
      alert("No genres/topics to generate passages from.");
      return;
    }
    setIsGenerating(true);
    setError(null);

    try {
      const newPassages: GeneratedPassage[] = [];
      for (let genreTopics of topicsState) {
        const { genre, topics } = genreTopics;
        const vocabularyList: string[] = [];
        for (let topic of topics) {
          const payload = buildPassagePrompt(
            genre,
            topic,
            section,
            proficiency,
            vocabularyList,
            language
          );
          const result = await callOpenAI(payload);
          if (!result)
            throw new Error(
              `API error generating passage for topic "${topic}"`
            );
          let jsonString = result.response || "";
          const startIndex = jsonString.indexOf("{");
          const endIndex = jsonString.lastIndexOf("}") + 1;
          if (startIndex === -1 || endIndex === 0)
            throw new Error(
              `Could not find a JSON object in the response for topic "${topic}".`
            );
          jsonString = jsonString.slice(startIndex, endIndex).trim();
          const parsed = JSON.parse(jsonString);
          newPassages.push({
            topic,
            passage: {
              Identifier: "",
              Level: section, // Changed from proficiency to section
              Genre: genre,
              Base_Lang_Code: "EN",
              Base_Lang_Title: "",
              Base_Lang_Description: "",
              Base_Lang_Passage: "",
              Base_Lang_Question_1: "",
              Base_Lang_Answer_1: "",
              Base_Lang_Options_1: "[]",
              Base_Lang_Question_2: "",
              Base_Lang_Answer_2: "",
              Base_Lang_Options_2: "[]",
              Base_Lang_Question_3: "",
              Base_Lang_Answer_3: "",
              Base_Lang_Options_3: "[]",
              Base_Lang_Question_4: "",
              Base_Lang_Answer_4: "",
              Base_Lang_Options_4: "[]",
              Targ_Lang_Code: language,
              Targ_Lang_Title: parsed.Name || "",
              Targ_Lang_Description: parsed.Description || "",
              Targ_Lang_Passage: parsed.Passage || "",
              Targ_Passage_Word_Timings: "",
              Targ_Passage_Audio_URL: "",
              Targ_Lang_Question_1: parsed.Question_1 || "",
              Targ_Lang_Answer_1: parsed.Answer_1 || "",
              Targ_Lang_Options_1: JSON.stringify(parsed.Options_1 || []),
              Targ_Lang_Question_2: parsed.Question_2 || "",
              Targ_Lang_Answer_2: parsed.Answer_2 || "",
              Targ_Lang_Options_2: JSON.stringify(parsed.Options_2 || []),
              Targ_Lang_Question_3: parsed.Question_3 || "",
              Targ_Lang_Answer_3: parsed.Answer_3 || "",
              Targ_Lang_Options_3: JSON.stringify(parsed.Options_3 || []),
              Targ_Lang_Question_4: parsed.Question_4 || "",
              Targ_Lang_Answer_4: parsed.Answer_4 || "",
              Targ_Lang_Options_4: JSON.stringify(parsed.Options_4 || []),
              ImageURL: "",
              Prompt: "",
              Section: section,
            },
          });
        }
      }
      imagesGeneratedRef.current = false;
      setPassagesState(newPassages);
      setTimeout(() => {
        newPassages.forEach((_, idx) => handleGenerateImage(idx));
      }, 500);
    } catch (err: any) {
      console.error("Error generating passages:", err);
      setError(err.message);
    } finally {
      setIsGenerating(false);
    }
  }, [topicsState, proficiency, language, section]);

  const handlePassageFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const localUrl = URL.createObjectURL(file);
      setPassagesState((prev) =>
        prev.map((item, i) =>
          i === index
            ? { ...item, passage: { ...item.passage, ImageURL: localUrl } }
            : item
        )
      );
    }
  };

  const handleDeletePassage = (index: number) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this passage?"
    );
    if (!confirmed) return;
    setPassagesState((prev) => prev.filter((_, i) => i !== index));
  };

  const handleEditPassage = (index: number) => {
    setEditingIndex(index);
    setEditModalData({ ...passagesState[index].passage });
    setIsEditModalOpen(true);
    setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 100);
  };

  const handleGenerateImage = async (index: number) => {
    const passageItem = passagesState[index];
    if (!passageItem) {
      console.error("No passage found at index", index);
      return;
    }
    setPassagesState((prev) =>
      prev.map((item, i) =>
        i === index
          ? { ...item, passage: { ...item.passage, isRegenerating: true } }
          : item
      )
    );
    const cleanPassage = passageItem.passage.Targ_Lang_Passage.replace(
      /\\n/g,
      "\n"
    );
    let payload;
    if (
      passageItem.passage.customPrompt &&
      passageItem.passage.customPrompt.trim() !== ""
    ) {
      payload = buildPassageCustomImagePayload(
        cleanPassage,
        passageItem.passage.customPrompt,
        language
      );
    } else {
      payload = buildPassageInitialImagePromptPayload(cleanPassage, language);
    }
    try {
      console.log("Sending image payload:", payload);
      const imageUrl = await generateImage(payload);
      if (!imageUrl) throw new Error("Image generation failed");
      setPassagesState((prev) =>
        prev.map((item, i) =>
          i === index
            ? {
                ...item,
                passage: {
                  ...item.passage,
                  ImageURL: imageUrl,
                  isRegenerating: false,
                },
              }
            : item
        )
      );
    } catch (error) {
      console.error("Error generating image:", error);
      setPassagesState((prev) =>
        prev.map((item, i) =>
          i === index
            ? {
                ...item,
                passage: { ...item.passage, isRegenerating: false },
              }
            : item
        )
      );
    }
  };

  const handleImagePromptChange = (index: number, value: string) => {
    setPassagesState((prev) =>
      prev.map((item, i) =>
        i === index
          ? { ...item, passage: { ...item.passage, customPrompt: value } }
          : item
      )
    );
  };

  const handleSave = async () => {
    if (passagesState.length === 0) {
      alert("No passages to save!");
      return;
    }

    setIsGenerating(true);
    setError(null);

    try {
      const passagesToSave = passagesState.map(({ passage, topic }) => {
        const { isRegenerating, customPrompt, ...cleanPassage } = passage;
        return {
          ...cleanPassage,
          Identifier: uuidv4(),
          Prompt: customPrompt || "",
          Level: section, // Explicitly ensure Level is section (redundant here but safe)
        };
      });

      await Promise.all(
        passagesToSave.map((passage) =>
          addPassage(passage)
            .then(() =>
              console.log(`Passage ${passage.Identifier} saved successfully`)
            )
            .catch((err) => {
              throw new Error(
                `Failed to save passage ${passage.Identifier}: ${err.message}`
              );
            })
        )
      );

      alert("Passages saved successfully!");
      const fetchedPassages = await fetchPassages(section);
      setExistingPassages(fetchedPassages);
    } catch (err: any) {
      console.error("Error saving passages:", err);
      setError(err.message || "Failed to save passages.");
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSaveEdit = () => {
    if (editingIndex === null || !editModalData) return;
    setPassagesState((prev) =>
      prev.map((item, i) =>
        i === editingIndex ? { ...item, passage: editModalData } : item
      )
    );
    setIsEditModalOpen(false);
    setEditModalData(null);
    setEditingIndex(null);
  };

  useEffect(() => {
    if (passagesState.length > 0 && !imagesGeneratedRef.current) {
      imagesGeneratedRef.current = true;
      setTimeout(() => {
        passagesState.forEach((_, idx) => handleGenerateImage(idx));
      }, 0);
    }
  }, [passagesState]);

  useEffect(() => {
    async function fetchSections() {
      try {
        const response = await fetch(
          "https://jb3lo2ttk6.execute-api.us-east-1.amazonaws.com/api/v1/all-sections?Targ_Lang_Code=ES"
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        const reviewSections: string[] = [];
        data.sections.forEach((section: any) => {
          if (Array.isArray(section.Lessons)) {
            section.Lessons.forEach((lesson: any) => {
              if (lesson.ReviewSection) {
                reviewSections.push(String(lesson.ReviewSection));
              }
            });
          }
        });
        const uniqueSections = Array.from(new Set(reviewSections));
        setSections(uniqueSections);
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    }
    fetchSections();
  }, []);

  useEffect(() => {
    async function loadExistingPassages() {
      try {
        const fetchedPassages = await fetchPassages(section);
        setExistingPassages(fetchedPassages);
      } catch (error: any) {
        console.error("Error fetching existing passages:", error);
        if (error.response && error.response.status === 404) {
          setExistingPassages([]);
        }
      }
    }
    loadExistingPassages();
  }, [section]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Passage Generator</h1>

      <div className={styles.formRow}>
        <label className={styles.label}>
          <strong>Choose Language: </strong>
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            className={styles.input}
          >
            {AVAILABLE_LANGUAGES.map((lang) => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            ))}
          </select>
        </label>
      </div>

      <form onSubmit={handleAddGenre} className={styles.form}>
        <div className={styles.formRow}>
          <label className={styles.label}>
            <strong>Number of Topics/Passages per Genre:</strong>
            <input
              type="number"
              min={1}
              value={topicsCount}
              onChange={(e) => setTopicsCount(Number(e.target.value))}
              className={styles.input}
            />
          </label>
          <label className={styles.label}>
            <strong>Proficiency Level: </strong>
            <select
              value={proficiency}
              onChange={(e) => setProficiency(e.target.value)}
              className={styles.input}
            >
              {PROFICIENCY_LEVELS.map((lvl) => (
                <option key={lvl} value={lvl}>
                  {lvl}
                </option>
              ))}
            </select>
          </label>
          <label className="label">
            Section:
            <select
              className="input"
              value={section}
              onChange={(e) => setSection(e.target.value)}
            >
              {sections.map((sec, idx) => (
                <option key={idx} value={sec}>
                  {sec}
                </option>
              ))}
            </select>
          </label>
        </div>
        <input
          type="text"
          placeholder="Type a genre and press Enter"
          value={newGenre}
          onChange={(e) => setNewGenre(e.target.value)}
          className={styles.input}
        />
        <button type="submit" className={styles.button}>
          Add Genre
        </button>
        <div className={styles.wordListContainer}>
          {genres.map((genre, idx) => (
            <span key={idx} className={styles.wordItem}>
              {genre}
              <button
                onClick={() => removeGenre(idx)}
                className={styles.xButton}
              >
                ×
              </button>
            </span>
          ))}
        </div>
      </form>

      <button
        onClick={handleGenerateTopics}
        disabled={isGenerating || genres.length === 0}
        className={styles.button}
      >
        {isGenerating ? "Generating..." : "Generate Topics"}
      </button>

      {error && <p className={styles.error}>Error: {error}</p>}

      {topicsState.length > 0 && (
        <div>
          <h2>Generated Topics</h2>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Genre</th>
                <th>Topics</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {topicsState.map((item, idx) => (
                <tr key={idx}>
                  <td>
                    <strong>{item.genre}</strong>
                  </td>
                  <td>
                    {item.topics.map((topic, i) => (
                      <div key={i}>{topic}</div>
                    ))}
                  </td>
                  <td>
                    <button
                      onClick={() => handleRegenerateTopics(idx)}
                      className={styles.button}
                    >
                      Regenerate
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {topicsState.length > 0 && (
        <button
          onClick={handleGeneratePassages}
          disabled={isGenerating}
          className={styles.button}
        >
          {isGenerating ? "Generating Passages..." : "Generate Passages"}
        </button>
      )}

      {passagesState.length > 0 && (
        <div>
          <h2>Generated Passages</h2>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Genre</th>
                <th>Topic</th>
                <th>Passage Info</th>
                <th>Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {passagesState.map(({ passage, topic }, idx) => (
                <tr key={idx}>
                  <td>{passage.Genre}</td>
                  <td>{topic}</td>
                  <td>
                    <strong>{passage.Targ_Lang_Title}</strong>
                    <br />
                    <em>{passage.Targ_Lang_Description}</em>
                    <br />
                    <p>{passage.Targ_Lang_Passage.replace(/\\n/g, "\n")}</p>
                    <div>
                      <strong>Q1: </strong>
                      {passage.Targ_Lang_Question_1}
                      <br />
                      <strong>Options:</strong>{" "}
                      {JSON.parse(passage.Targ_Lang_Options_1).join(", ")}
                      <br />
                      <strong>Correct:</strong> {passage.Targ_Lang_Answer_1}
                    </div>
                    <div>
                      <strong>Q2: </strong>
                      {passage.Targ_Lang_Question_2}
                      <br />
                      <strong>Options:</strong>{" "}
                      {JSON.parse(passage.Targ_Lang_Options_2).join(", ")}
                      <br />
                      <strong>Correct:</strong> {passage.Targ_Lang_Answer_2}
                    </div>
                    <div>
                      <strong>Q3: </strong>
                      {passage.Targ_Lang_Question_3}
                      <br />
                      <strong>Options:</strong>{" "}
                      {JSON.parse(passage.Targ_Lang_Options_3).join(", ")}
                      <br />
                      <strong>Correct:</strong> {passage.Targ_Lang_Answer_3}
                    </div>
                    <div>
                      <strong>Q4: </strong>
                      {passage.Targ_Lang_Question_4}
                      <br />
                      <strong>Options:</strong>{" "}
                      {JSON.parse(passage.Targ_Lang_Options_4).join(", ")}
                      <br />
                      <strong>Correct:</strong> {passage.Targ_Lang_Answer_4}
                    </div>
                  </td>
                  <td className={styles.imageCell}>
                    {passage.ImageURL ? (
                      <div>
                        <img
                          src={passage.ImageURL}
                          alt="Generated"
                          className={styles.image}
                        />
                      </div>
                    ) : (
                      <p>Generating image…</p>
                    )}
                    <input
                      type="text"
                      value={passage.customPrompt || ""}
                      placeholder="Enter prompt (e.g., A cartoonish, semi-realistic …)"
                      onChange={(e) =>
                        handleImagePromptChange(idx, e.target.value)
                      }
                      className={`${styles.input} ${styles.imageInput}`}
                    />
                    <button
                      onClick={() => handleGenerateImage(idx)}
                      className={styles.button}
                      disabled={passage.isRegenerating}
                    >
                      {passage.isRegenerating ? "Regenerating…" : "Regenerate"}
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => handleEditPassage(idx)}
                      className={styles.button}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeletePassage(idx)}
                      className={styles.xButton}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            onClick={handleSave}
            className={styles.saveButton}
            disabled={isGenerating}
          >
            {isGenerating ? "Saving..." : "Save"}
          </button>
        </div>
      )}

      <div>
        <h2>Existing Passages</h2>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Genre</th>
              <th>Passage Info</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {existingPassages.length === 0 ? (
              <tr>
                <td colSpan={3}>No passages added yet!</td>
              </tr>
            ) : (
              existingPassages.map((passage, idx) => (
                <tr key={idx}>
                  <td>{passage.Genre}</td>
                  <td>
                    <strong>{passage.Targ_Lang_Title}</strong>
                    <br />
                    <em>{passage.Targ_Lang_Description}</em>
                    <br />
                    <p>{passage.Targ_Lang_Passage.replace(/\\n/g, "\n")}</p>
                    <div>
                      <strong>Q1: </strong>
                      {passage.Targ_Lang_Question_1}
                      <br />
                      <strong>Options:</strong>{" "}
                      {JSON.parse(passage.Targ_Lang_Options_1).join(", ")}
                      <br />
                      <strong>Correct:</strong> {passage.Targ_Lang_Answer_1}
                    </div>
                    <div>
                      <strong>Q2: </strong>
                      {passage.Targ_Lang_Question_2}
                      <br />
                      <strong>Options:</strong>{" "}
                      {JSON.parse(passage.Targ_Lang_Options_2).join(", ")}
                      <br />
                      <strong>Correct:</strong> {passage.Targ_Lang_Answer_2}
                    </div>
                    <div>
                      <strong>Q3: </strong>
                      {passage.Targ_Lang_Question_3}
                      <br />
                      <strong>Options:</strong>{" "}
                      {JSON.parse(passage.Targ_Lang_Options_3).join(", ")}
                      <br />
                      <strong>Correct:</strong> {passage.Targ_Lang_Answer_3}
                    </div>
                    <div>
                      <strong>Q4: </strong>
                      {passage.Targ_Lang_Question_4}
                      <br />
                      <strong>Options:</strong>{" "}
                      {JSON.parse(passage.Targ_Lang_Options_4).join(", ")}
                      <br />
                      <strong>Correct:</strong> {passage.Targ_Lang_Answer_4}
                    </div>
                  </td>
                  <td className={styles.imageCell}>
                    {passage.ImageURL ? (
                      <img
                        src={passage.ImageURL}
                        alt="Existing Passage"
                        className={styles.image}
                      />
                    ) : (
                      <p>No Image</p>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {isEditModalOpen && editModalData && (
        <div ref={modalRef} className={styles.modalBackdrop}>
          <div className={styles.modal}>
            <h2>Edit Passage</h2>
            <label className={styles.modalEdit}>
              <strong>Passage Text:</strong>
              <textarea
                value={editModalData.Targ_Lang_Passage}
                onChange={(e) =>
                  setEditModalData((prev) =>
                    prev ? { ...prev, Targ_Lang_Passage: e.target.value } : null
                  )
                }
                className={styles.modalInput}
              />
            </label>
            <label className={styles.modalEdit}>
              <strong>Question:</strong>
              <input
                type="text"
                value={editModalData.Targ_Lang_Question_1}
                onChange={(e) =>
                  setEditModalData((prev) =>
                    prev
                      ? { ...prev, Targ_Lang_Question_1: e.target.value }
                      : null
                  )
                }
                className={styles.modalInput}
              />
            </label>
            <label className={styles.modalEdit}>
              <strong>Options (comma separated):</strong>
              <input
                type="text"
                value={JSON.parse(editModalData.Targ_Lang_Options_1).join(", ")}
                onChange={(e) => {
                  const newOpts = e.target.value
                    .split(",")
                    .map((o) => o.trim());
                  setEditModalData((prev) =>
                    prev
                      ? {
                          ...prev,
                          Targ_Lang_Options_1: JSON.stringify(newOpts),
                        }
                      : null
                  );
                }}
                className={styles.modalInput}
              />
            </label>
            <label className={styles.modalEdit}>
              <strong>Correct Answer:</strong>
              <input
                type="text"
                value={editModalData.Targ_Lang_Answer_1}
                onChange={(e) =>
                  setEditModalData((prev) =>
                    prev
                      ? { ...prev, Targ_Lang_Answer_1: e.target.value }
                      : null
                  )
                }
                className={styles.modalInput}
              />
            </label>
            <div className={styles.modalButtons}>
              <button onClick={handleSaveEdit} className={styles.button}>
                Save
              </button>
              <button
                onClick={() => setIsEditModalOpen(false)}
                className={styles.button}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Passages;

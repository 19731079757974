// src/AvgTime/AvgTime.tsx
import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface AvgTimeEvent {
  userId: string;
  date: string;
  duration: number; // in seconds
}

interface AvgTimeProps {
  timeframe: string;
  events: AvgTimeEvent[];
  startDate: string;
  endDate: string;
}

const AvgTime: React.FC<AvgTimeProps> = ({
  timeframe,
  events,
  startDate,
  endDate,
}) => {
  const { labels, averages } = useMemo(() => {
    if (!events || events.length === 0) {
      return { labels: [] as string[], averages: [] as number[] };
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    const allLabels = generateLabels(timeframe, start, end);

    // Group events by timeframe bucket
    const grouped: Record<string, { total: number; count: number }> = {};
    for (const lbl of allLabels) {
      grouped[lbl] = { total: 0, count: 0 };
    }

    for (const evt of events) {
      const dateObj = new Date(evt.date);
      const bucketLabel = getBucketLabel(timeframe, dateObj);

      if (grouped[bucketLabel]) {
        grouped[bucketLabel].total += evt.duration;
        grouped[bucketLabel].count++;
      }
    }

    // Calculate averages
    const averages = allLabels.map((lbl) => {
      const group = grouped[lbl];
      return group.count > 0 ? Math.round(group.total / group.count) : 0;
    });

    return { labels: allLabels, averages };
  }, [events, startDate, endDate, timeframe]);

  if (labels.length === 0) {
    return (
      <div>
        <h2>Average Time Spent</h2>
        <p>No data for this range.</p>
      </div>
    );
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: `Average Session Duration (seconds)`,
        data: averages,
        borderColor: "#4CAF50",
        backgroundColor: "#4CAF50",
      },
    ],
  };

  return (
    <div>
      <h2>Average Time Spent</h2>
      <Line data={chartData} />
    </div>
  );
};

// Helper to generate the full list of labels (daily/weekly/monthly)
function generateLabels(timeframe: string, start: Date, end: Date): string[] {
  if (timeframe === "daily") {
    return getAllDaysInRange(start, end);
  } else if (timeframe === "weekly") {
    return getAllWeeksInRange(start, end);
  } else {
    return getAllMonthsInRange(start, end);
  }
}

// Helper to get the actual bucket label for a single event's date
function getBucketLabel(timeframe: string, dateObj: Date): string {
  if (timeframe === "daily") {
    return dateObj.toISOString().split("T")[0]; // e.g. "2025-01-10"
  } else if (timeframe === "weekly") {
    const sunday = new Date(dateObj);
    sunday.setDate(sunday.getDate() - sunday.getDay());
    return sunday.toISOString().split("T")[0]; // e.g. "2025-01-05"
  } else {
    // monthly
    const y = dateObj.getFullYear();
    const m = String(dateObj.getMonth() + 1).padStart(2, "0");
    return `${y}-${m}`; // e.g. "2025-01"
  }
}

/** Generate daily labels from start to end (inclusive) */
function getAllDaysInRange(start: Date, end: Date): string[] {
  const days: string[] = [];
  const current = new Date(start);
  while (current <= end) {
    days.push(current.toISOString().split("T")[0]);
    current.setDate(current.getDate() + 1);
  }
  return days;
}

/** Generate weekly labels (Sundays) in [start, end] */
function getAllWeeksInRange(start: Date, end: Date): string[] {
  const labels: string[] = [];
  // find first Sunday on or before start
  const startSunday = new Date(start);
  startSunday.setDate(startSunday.getDate() - startSunday.getDay());

  // find last Sunday on or before end
  const endSunday = new Date(end);
  endSunday.setDate(endSunday.getDate() - endSunday.getDay());

  let current = startSunday;
  while (current <= endSunday) {
    labels.push(current.toISOString().split("T")[0]);
    current.setDate(current.getDate() + 7);
  }
  return labels;
}

/** Generate monthly labels (YYYY-MM) in [start, end] */
function getAllMonthsInRange(start: Date, end: Date): string[] {
  const labels: string[] = [];
  let cYear = start.getFullYear();
  let cMonth = start.getMonth();

  const endYear = end.getFullYear();
  const endMonth = end.getMonth();

  while (cYear < endYear || (cYear === endYear && cMonth <= endMonth)) {
    const mm = String(cMonth + 1).padStart(2, "0");
    labels.push(`${cYear}-${mm}`);
    cMonth++;
    if (cMonth > 11) {
      cMonth = 0;
      cYear++;
    }
  }
  return labels;
}
export default AvgTime;

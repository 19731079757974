// src/LogIns/LogIns.tsx
import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface LoginEvent {
  userId: string;
  date: string; // e.g. "2025-01-10T14:22:00Z"
}

interface LoginsProps {
  timeframe: string; // "daily", "weekly", or "monthly"
  events: LoginEvent[]; // already filtered by the parent
  startDate: string; // "YYYY-MM-DD"
  endDate: string; // "YYYY-MM-DD"
}

const Logins: React.FC<LoginsProps> = ({
  timeframe,
  events,
  startDate,
  endDate,
}) => {
  // 1) Always call hooks in the same order
  const { labels, totalLoginsArr, uniqueLoginsArr } = useMemo(() => {
    // If no events or invalid date range, just return empty arrays
    if (!events || events.length === 0) {
      return {
        labels: [] as string[],
        totalLoginsArr: [] as number[],
        uniqueLoginsArr: [] as number[],
      };
    }

    // Convert the user-selected date strings into actual Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Generate all possible daily/weekly/monthly labels in [start, end]
    const allLabels = generateLabels(timeframe, start, end);

    // Initialize each label to { total: 0, users: new Set() }
    interface GroupInfo {
      total: number;
      users: Set<string>;
    }
    const grouped: Record<string, GroupInfo> = {};
    for (const lbl of allLabels) {
      grouped[lbl] = { total: 0, users: new Set() };
    }

    // For each event, figure out which bucket it belongs to
    for (const evt of events) {
      const dateObj = new Date(evt.date);
      const bucketLabel = getBucketLabel(timeframe, dateObj);

      // If the label is in our range, increment totals
      if (grouped[bucketLabel]) {
        grouped[bucketLabel].total++;
        grouped[bucketLabel].users.add(evt.userId);
      }
    }

    // Convert object to arrays for Chart.js
    const total = allLabels.map((lbl) => grouped[lbl].total);
    const uniques = allLabels.map((lbl) => grouped[lbl].users.size);

    return {
      labels: allLabels,
      totalLoginsArr: total,
      uniqueLoginsArr: uniques,
    };
  }, [events, startDate, endDate, timeframe]);

  // 2) If no data, show "no logins" message
  if (labels.length === 0) {
    return (
      <div>
        <h2>Log Ins</h2>
        <p>No logins for this range.</p>
      </div>
    );
  }

  // 3) Build chart data
  const chartData = {
    labels,
    datasets: [
      {
        label: `${timeframe} Total Logins`,
        data: totalLoginsArr,
        borderColor: "#dc3912",
        backgroundColor: "#dc3912",
      },
      {
        label: `${timeframe} Unique Users`,
        data: uniqueLoginsArr,
        borderColor: "#3366cc",
        backgroundColor: "#3366cc",
      },
    ],
  };

  return (
    <div>
      <h2>Log Ins</h2>
      <Line data={chartData} />
    </div>
  );
};

// Helper to generate the full list of labels (daily/weekly/monthly)
function generateLabels(timeframe: string, start: Date, end: Date): string[] {
  if (timeframe === "daily") {
    return getAllDaysInRange(start, end);
  } else if (timeframe === "weekly") {
    return getAllWeeksInRange(start, end);
  } else {
    return getAllMonthsInRange(start, end);
  }
}

// Helper to get the actual bucket label for a single event's date
function getBucketLabel(timeframe: string, dateObj: Date): string {
  if (timeframe === "daily") {
    return dateObj.toISOString().split("T")[0]; // e.g. "2025-01-10"
  } else if (timeframe === "weekly") {
    const sunday = new Date(dateObj);
    sunday.setDate(sunday.getDate() - sunday.getDay());
    return sunday.toISOString().split("T")[0]; // e.g. "2025-01-05"
  } else {
    // monthly
    const y = dateObj.getFullYear();
    const m = String(dateObj.getMonth() + 1).padStart(2, "0");
    return `${y}-${m}`; // e.g. "2025-01"
  }
}

/** Generate daily labels from start to end (inclusive) */
function getAllDaysInRange(start: Date, end: Date): string[] {
  const days: string[] = [];
  const current = new Date(start);
  while (current <= end) {
    days.push(current.toISOString().split("T")[0]);
    current.setDate(current.getDate() + 1);
  }
  return days;
}

/** Generate weekly labels (Sundays) in [start, end] */
function getAllWeeksInRange(start: Date, end: Date): string[] {
  const labels: string[] = [];
  // find first Sunday on or before start
  const startSunday = new Date(start);
  startSunday.setDate(startSunday.getDate() - startSunday.getDay());

  // find last Sunday on or before end
  const endSunday = new Date(end);
  endSunday.setDate(endSunday.getDate() - endSunday.getDay());

  let current = startSunday;
  while (current <= endSunday) {
    labels.push(current.toISOString().split("T")[0]);
    current.setDate(current.getDate() + 7);
  }
  return labels;
}

/** Generate monthly labels (YYYY-MM) in [start, end] */
function getAllMonthsInRange(start: Date, end: Date): string[] {
  const labels: string[] = [];
  let cYear = start.getFullYear();
  let cMonth = start.getMonth();

  const endYear = end.getFullYear();
  const endMonth = end.getMonth();

  while (cYear < endYear || (cYear === endYear && cMonth <= endMonth)) {
    const mm = String(cMonth + 1).padStart(2, "0");
    labels.push(`${cYear}-${mm}`);
    cMonth++;
    if (cMonth > 11) {
      cMonth = 0;
      cYear++;
    }
  }
  return labels;
}

export default Logins;

// src/TopUsers.tsx
import React, { useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartEvent,
  ActiveElement,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface TopUsersProps {
  timeframe: string;
  events: { userId: string; date: string }[]; // Already transformed in parent
  onUserClick?: (userId: string) => void;     // Callback when a bar is clicked
}

const TopUsers: React.FC<TopUsersProps> = ({ timeframe, events, onUserClick }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 5;

  // 1) Compute total counts
  const topUsersAll = useMemo(() => {
    const counts: Record<string, number> = {};
    events.forEach((evt) => {
      counts[evt.userId] = (counts[evt.userId] || 0) + 1;
    });
    const arr = Object.entries(counts).map(([userId, count]) => ({ userId, count }));
    arr.sort((a, b) => b.count - a.count); // descending
    return arr;
  }, [events]);

  // 2) Pagination
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const pageUsers = topUsersAll.slice(startIndex, endIndex);

  // 3) Prepare chart data
  const chartLabels = pageUsers.map((item) => item.userId);
  const chartValues = pageUsers.map((item) => item.count);

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Logins",
        data: chartValues,
        backgroundColor: "#ffa500",
        borderColor: "#ff9a00",
        borderWidth: 1,
      },
    ],
  };

  // 4) Chart options: handle bar click
  const barOptions = {
    indexAxis: "y" as const,
    onClick: (event: ChartEvent, elements: ActiveElement[]) => {
      // If user clicked on a bar
      if (elements.length && onUserClick) {
        const index = elements[0].index;
        const clickedUser = pageUsers[index].userId;
        onUserClick(clickedUser);
      }
    },
    scales: {
      x: { beginAtZero: true },
      y: {},
    },
    plugins: {
      legend: { position: "top" as const },
    },
  };

  // Pagination controls
  const canPrev = currentPage > 0;
  const canNext = endIndex < topUsersAll.length;
  const handlePrev = () => canPrev && setCurrentPage((p) => p - 1);
  const handleNext = () => canNext && setCurrentPage((p) => p + 1);

  // Render
  return (
    <div>
      <h2>Top Users</h2>
      <div style={{ maxWidth: 600 }}>
        <Bar data={chartData} options={barOptions} />
      </div>

      <div style={{ marginTop: "1rem" }}>
        <button onClick={handlePrev} disabled={!canPrev}>
          Previous
        </button>
        <span style={{ margin: "0 1rem" }}>
          Page {currentPage + 1} of {Math.ceil(topUsersAll.length / pageSize)}
        </span>
        <button onClick={handleNext} disabled={!canNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default TopUsers;

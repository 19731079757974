import axios from "axios";
import { VocabularyData } from "../types";

const MOBILEAPP_API_URL =
  "https://jb3lo2ttk6.execute-api.us-east-1.amazonaws.com";
const DATALOADER_API_URL =
  "https://duaipi78ei.execute-api.us-east-1.amazonaws.com/juno-middleware";

export const fetchSections = async (
  targetLanguageCode: string
): Promise<string[]> => {
  try {
    const response = await axios.get(
      `${MOBILEAPP_API_URL}/api/v1/all-sections?Targ_Lang_Code=${targetLanguageCode}`
    );
    console.log(
      `Raw response for Targ_Lang_Code=${targetLanguageCode}:`,
      response.data
    );

    // Check if response has 'sections' and process accordingly
    if (
      !response.data ||
      !response.data.sections ||
      !Array.isArray(response.data.sections)
    ) {
      console.warn("No valid 'sections' array in response:", response.data);
      return [];
    }

    const reviewSections: string[] = [];
    response.data.sections.forEach((section: any) => {
      // Try to extract ReviewSection from Lessons if available
      if (Array.isArray(section.Lessons)) {
        section.Lessons.forEach((lesson: any) => {
          if (lesson.ReviewSection) {
            reviewSections.push(String(lesson.ReviewSection));
          }
        });
      }
      // Fallback: Use section Identifier if present and no ReviewSections found yet
      if (section.Identifier && !reviewSections.length) {
        reviewSections.push(String(section.Identifier));
      }
    });

    const uniqueSections = Array.from(new Set(reviewSections));
    console.log(
      `Extracted sections for ${targetLanguageCode}:`,
      uniqueSections
    );
    return uniqueSections;
  } catch (error: any) {
    console.error("Error fetching sections:", error);
    return []; // Silently return empty array instead of throwing error
  }
};

export const fetchVocabularyWords = async (
  section: string
): Promise<VocabularyData[]> => {
  try {
    const response = await axios.get(`${MOBILEAPP_API_URL}/api/v1/vocabulary`, {
      params: {
        section: section,
      },
    });

    console.log("fetchVocabularyWords response.data:", response.data);

    if (!Array.isArray(response.data)) {
      if (response.data.items && Array.isArray(response.data.items)) {
        return response.data.items.map((item: any) => ({
          Identifier: item.Identifier?.S || "",
          Level: item.Level?.S || "",
          Base_Word: item.Base_Word?.S || "",
          Base_Lang_Code: item.Base_Lang_Code?.S || "",
          Base_Lang_Options: item.Base_Lang_Options?.S || "[]",
          Targ_Word: item.Targ_Word?.S || "",
          Targ_Lang_Code: item.Targ_Lang_Code?.S || "",
          Targ_Lang_Options: item.Targ_Lang_Options?.S || "[]",
          Explanation_Word_Timing: item.Explanation_Word_Timing?.S || "",
          Phonetic_Transcription: item.Phonetic_Transcription?.S || "",
          Pronunciation_Explanation: item.Pronunciation_Explanation?.S || "",
          Pronunciation_Explanation_Audio:
            item.Pronunciation_Explanation_Audio?.S || "",
          Targ_Syllable: item.Targ_Syllable?.S || "",
          Targ_Syllable_Sounds: item.Targ_Syllable_Sounds?.S || "",
          Word_Audio: item.Word_Audio?.S || "",
          ImageURL: item.ImageURL?.S || "",
        }));
      }
      console.warn(
        "Response data is not an array or does not contain 'items':",
        response.data
      );
      return [];
    }

    return response.data.map((item: any) => ({
      Identifier: item.Identifier?.S || "",
      Level: item.Level?.S || "",
      Base_Word: item.Base_Word?.S || "",
      Base_Lang_Code: item.Base_Lang_Code?.S || "",
      Base_Lang_Options: item.Base_Lang_Options?.S || "[]",
      Targ_Word: item.Targ_Word?.S || "",
      Targ_Lang_Code: item.Targ_Lang_Code?.S || "",
      Targ_Lang_Options: item.Targ_Lang_Options?.S || "[]",
      Explanation_Word_Timing: item.Explanation_Word_Timing?.S || "",
      Phonetic_Transcription: item.Phonetic_Transcription?.S || "",
      Pronunciation_Explanation: item.Pronunciation_Explanation?.S || "",
      Pronunciation_Explanation_Audio:
        item.Pronunciation_Explanation_Audio?.S || "",
      Targ_Syllable: item.Targ_Syllable?.S || "",
      Targ_Syllable_Sounds: item.Targ_Syllable_Sounds?.S || "",
      Word_Audio: item.Word_Audio?.S || "",
      ImageURL: item.ImageURL?.S || "",
    }));
  } catch (error: any) {
    console.error("Error fetching vocabulary words:", error);
    return []; // Silently return empty array
  }
};

export const uploadImageAndGetUrl = async (
  croppedBlob: Blob,
  imageName: string
) => {
  const presignedUrlResponse = await fetch(
    `${DATALOADER_API_URL}/api/v1/upload-vocab-image`,
    {
      method: "POST",
      body: JSON.stringify({
        fileName: imageName,
        fileType: croppedBlob.type,
      }),
    }
  );
  if (!presignedUrlResponse.ok) throw new Error("Failed to get presigned URL");
  const presignedUrlData = await presignedUrlResponse.json();
  const presignedUrl = presignedUrlData.url;
  const uploadResponse = await fetch(presignedUrl, {
    method: "PUT",
    headers: { "Content-Type": croppedBlob.type },
    body: croppedBlob,
  });
  if (!uploadResponse.ok)
    throw new Error("Failed to upload image to presigned URL");
  return presignedUrl.split("?")[0];
};

export const addVocabulary = async (data: VocabularyData) => {
  const response = await axios.post(
    `${DATALOADER_API_URL}/api/v1/add-vocabulary`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return response.data;
};

export const updateVocabulary = async (
  identifier: string,
  level: string,
  updatedData: Partial<VocabularyData>
) => {
  const response = await axios.put(
    `${DATALOADER_API_URL}/update-vocabulary/${identifier}/${level}`,
    updatedData,
    { headers: { "Content-Type": "application/json" } }
  );
  return response.data;
};

export const deleteVocabulary = async (identifier: string, level: string) => {
  const response = await axios.delete(
    `${DATALOADER_API_URL}/delete-vocabulary/${identifier}/${level}`
  );
  return response.data;
};

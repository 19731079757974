// src/CourseMetrics.tsx
import React, { useState, useEffect } from "react";
import MultipleChoiceChart from "./MultipleChoice/MultipleChoice";
import Videos from "./Videos/Videos";
import Accent from "./Accent/Accent";
import Flashcard from "./Flashcard/Flashcard";
import MatchingAnswered from "./MatchingAnswered/MatchingAnswered";
import styles from "./Metrics.module.css";
import { Link } from "react-router-dom";

const CourseMetrics: React.FC = () => {
  // If you want a timeframe for these charts as well:
  const [timeframe, setTimeframe] = useState("weekly");
  const [apiData, setApiData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          "https://ob38jihfoj.execute-api.us-east-1.amazonaws.com/dev/api/v1/metric-queries/542bafbf-ee1d-4368-b2ac-0263bd532676"
        );
        const json = await response.json();
        setApiData(json);
      } catch (err) {
        console.error("Error fetching course metrics:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading course metrics data...</div>;
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Course Metrics</h1>

      <div className={styles.timeframeSelector}>
        <label htmlFor="timeframe">Select Timeframe: </label>
        <select
          id="timeframe"
          value={timeframe}
          onChange={(e) => setTimeframe(e.target.value)}
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>

      {/* Here are your "course" charts */}
      <div className={styles.chartContainer}>
        <div className={styles.chart}>
          <MultipleChoiceChart /* pass timeframe & apiData if needed */ />
        </div>
        <div className={styles.chart}>
          <Videos /* pass timeframe & apiData if needed */ />
        </div>
        <div className={styles.chart}>
          <Accent /* pass timeframe & apiData if needed */ />
        </div>
        <div className={styles.chart}>
          <Flashcard /* pass timeframe & apiData if needed */ />
        </div>
        <div className={styles.chart}>
          <MatchingAnswered /* pass timeframe & apiData if needed */ />
        </div>
      </div>
      <Link to="/metrics" className={styles.courseLink}>
        Back to Chatterbox Metrics
      </Link>
    </div>
  );
};

export default CourseMetrics;

// GenAIPrompts.tsx

// If you want to keep the same set of languages in this file:
const AVAILABLE_LANGUAGES = [
  "English",
  "Spanish",
  "Italian",
  "French",
  "German",
  "Portuguese",
];

export function buildGenAiPayload(sourceLang: string, words: string[]) {
  // Target languages: all available languages except the source language.
  const targetLangs = AVAILABLE_LANGUAGES.filter((lang) => lang !== sourceLang);

  // Build the user prompt, now explicitly listing the target languages.
  const userPrompt = `
[SOURCE_LANGUAGE] = [${sourceLang}].
List of words: [${words.join(", ")}].

Target languages (excluding the source language): ${targetLangs.join(", ")}.

Generate a JSON structure for the following [SOURCE_LANGUAGE] words: [INSERT WORDS].
For each word, include the following data:

SourceWord:
- Correct: The original word in [SOURCE_LANGUAGE].
- Options: A list of 4 options for the word in [SOURCE_LANGUAGE] (including the correct word and 3 plausible but incorrect distractors).

Translations:
For each of the target languages listed above, include:
- Correct: The correct translation of the word in that language.
- Options: A list of 4 options for the translation in that language (including the correct translation and 3 plausible but incorrect distractors).

Notes:
- Do not generate a translation in the same language as the source.
- Ensure each distractor is plausible but incorrect.
- Output must be valid JSON, formatted as an array of objects.
  `;

  // Build the system message, also showing the available target languages.
  const systemContent = `
Generate a JSON structure for user-inputted [SOURCE_LANGUAGE] words.
For each word, include the following data:

SourceWord:
- Correct
- Options

Translations:
- For each target language (from the list: ${targetLangs.join(
    ", "
  )}), include a { Correct, Options } object.

Output must be valid JSON. Here is an example format:

[
  {
    "SourceWord": {
      "Correct": "example",
      "Options": ["example", "distractor1", "distractor2", "distractor3"]
    },
    "Translations": {
      ${targetLangs
        .map(
          (lang) =>
            `"${lang}": { "Correct": "translation", "Options": ["translation", "distractor1", "distractor2", "distractor3"] }`
        )
        .join(",\n      ")}
    }
  },
  ...
]
  `;

  return {
    messages: [
      {
        role: "system",
        content: systemContent,
      },
      {
        role: "user",
        content: userPrompt,
      },
    ],
    temperature: 0.7,
    max_tokens: 700,
  };
}

export function buildImageDescriptionPrompt(
  sourceLang: string,
  sourceWord: string
) {
  const userPrompt = `
Please provide one detailed image description for a ${sourceLang} language learning course focusing on the subject "${sourceWord}".
The description must include the following exact phrases:
  "It should be animated yet somewhat realistic.",
  "Make it lively with the subject clearly shown.",
  "easy to identify for ${sourceLang} learners".
Return the final answer as a plain text string with no additional commentary.
  `;

  const systemContent = `
Generate a detailed image description suitable for a ${sourceLang} language learning course.
The description should vividly depict the subject "${sourceWord}" and be designed to help learners visually identify the subject.
Ensure that the following phrases are included exactly:
  "It should be animated yet somewhat realistic.",
  "Make it lively with the subject clearly shown.",
  "easy to identify for ${sourceLang} learners".
The description should be lively, engaging, and detailed.
  `;

  return {
    messages: [
      {
        role: "system",
        content: systemContent.trim(),
      },
      {
        role: "user",
        content: userPrompt.trim(),
      },
    ],
    temperature: 0.7,
    max_tokens: 300,
  };
}

/**
 * Generates an initial image prompt payload based on the provided image description.
 * This version returns a payload with a descriptive prompt and additional parameters,
 * where the prompt is directly taken from the string result generated by buildImageDescriptionPrompt.
 *
 * @param imageDescription The image description string from buildImageDescriptionPrompt.
 * @returns The payload for the image generation endpoint.
 */
export function buildGenAiInitialImagePromptPayload(imageDescription: string) {
  return {
    prompt: imageDescription,
    model: "dall-e-3",
    n: 1,
    size: "1024x1024",
    response_format: "url",
  };
}

/**
 * Generates an image prompt payload based on the provided SourceWord and a user-defined style prompt.
 * This version returns a payload with a descriptive prompt and additional parameters.
 *@param sourceLang
 * @param sourceWord The vocabulary word for which to generate the image.
 * @param stylePrompt The custom style prompt provided by the user.
 * @returns The payload for the image generation endpoint.
 */
export function buildGenAiImagePayload(
  sourceWord: string,
  stylePrompt: string,
  sourceLang: string
) {
  // Combine the vocabulary word with the custom style details.
  const prompt = `A depiction of "${sourceWord}" rendered in a description inspired by: "${stylePrompt}" suitable for a ${sourceLang} language learning course.`;

  return {
    prompt,
    model: "dall-e-3",
    n: 1,
    size: "1024x1024",
    response_format: "url",
  };
}

/**
 * Builds the OpenAI payload for generating multiple random topics
 * in the given language and genre.
 */
export function buildRandomTopicPrompt(
  genre: string,
  section: string,
  count: number,
  language: string
) {
  // This prompt now asks for topics that also relate to the chosen section.
  const userPrompt = `
    Generate exactly ${count} random topics or themes suitable for a story in the genre of "${genre}" that relate to the section "${section}".
    Each topic must be in ${language}.
    Do not include any additional text, explanations, or commentary beyond the topics themselves.
    Return the result as a valid JSON array of exactly ${count} strings, e.g., ["Topic 1", "Topic 2", "Topic 3"] if ${count} is 3.
    Ensure the output contains precisely ${count} items—no more, no less.
  `;

  return {
    messages: [
      {
        role: "user",
        content: userPrompt,
      },
    ],
    temperature: 0.7,
    max_tokens: 200,
  };
}

/**
 * Builds the OpenAI payload for generating a passage,
 * given Genre, Topic, ProficiencyLevel, and the relevant vocabularyList.
 */
export function buildPassagePrompt(
  genre: string,
  topic: string,
  section: string,
  proficiencyLevel: string,
  vocabularyList: string[],
  language: string
) {
  // Updated level descriptions remain the same:
  const levelDescriptions: { [key: string]: string } = {
    "1": "user that is incredibly new to the language and only can understand a few greetings. Can only understand present tense.",
    "2": "user that is incredibly new to the language and only can understand a few greetings and very basic words. Can only understand present tense.",
    "3": "user that is very new to the language and is learning basic verbs, questions, ser and estar. Can only understand present tense and is learning the words for locations, food, transportation, colors and adjectives. They have not learned past or future tense.",
    "4": "user that is new to the language and is learning present progressive and present tense. They only understand basic verbs, adjectives and nouns. They are learning the words for locations, ir, periphrastic future, tener. They have not learned past or future tense.",
    "5": `user that is new to the language and is learning emotions, common items, expressing opinions. These are the topics they are learning: Emotions, Common Items, To be able to, Opinions, Better & Worse, Thinking, Weather & Nature, Household items & Furniture, Entertainment & Free time, Some more verbs,
and these are the ones they have learned: Greetings, Useful Phrases, Useful Phrases: Part 2, Masculine vs Feminine, Plural vs Singular, Pronombres, Verbos principales, Verbos Regulares, Regular Verbs, Interrogatives, To be (Permanent), To be (Temporary), Present Progressive, Transportation, Colors, Adjectives, Possessive Pronouns, Possessive Adjective, To want, Food & Drink 1, To want Part two, Places, To go, Going to do, To Have, Tener + verb, Numbers 1-10, Numbers 11-100, Clothing.`,
    "6": `user that is new to the language that is learning these sections: To know, Clothes, Travel, Commands & Requests, Body parts, Hobbies, Technology, Months and have learned these sections: Greetings, Useful Phrases, Useful Phrases: Part 2, Masculine vs Feminine, Plural vs Singular, Pronombres, Verbos principales, Verbos Regulares, Regular Verbs, Interrogatives, To be (Permanent), To be (Temporary), Present Progressive, Transportation, Colors, Adjectives, Possessive Pronouns, Possessive Adjective, To want, Food & Drink 1, To want Part two, Places, To go, Going to do, To Have, Tener + verb, Numbers 1-10, Numbers 11-100, Clothing, Emotions, Common Items, To be able to, Opinions, Better & Worse, Thinking, Weather & Nature, Household items & Furniture, Entertainment & Free time, Some more verbs.
Write the story so that the user can understand the passage based off of what they have learned and what they are learning.`,
    "7": `A user that is a novice to the language that is learning these sections: Past tense - Preterite, More Preterite, Some More Preterite, Even More Preterite, Conditional, Story telling, Past tense - Imperfect, More Imperfect, Some More Imperfect, Even More Imperfect
and have learned these sections: Greetings, Useful Phrases, Useful Phrases: Part 2, Masculine vs Feminine, Plural vs Singular, Pronombres, Verbos principales, Verbos Regulares, Regular Verbs, Interrogatives, To be (Permanent), To be (Temporary), Present Progressive, Transportation, Colors, Adjectives, Possessive Pronouns, Possessive Adjective, To want, Food & Drink 1, To want Part two, Places, To go, Going to do, To Have, Tener + verb, Numbers 1-10, Numbers 11-100, Clothing, Emotions, Common Items, To be able to, Opinions, Better & Worse, Thinking, Weather & Nature, Household items & Furniture, Entertainment & Free time, Some more verbs.
Write the story so that the user can understand the passage based off of what they have learned and what they are learning.`,
    "8": `A user that is a novice to the language that is learning these sections: Cooking & Kitchen, Holidays and Celebrations, Formal, Professions, Reflexive verbs, Shopping & Money, School, Daily Routine, Verbs… Again. And has completed these sections: Greetings, Useful Phrases, Useful Phrases: Part 2, Masculine vs Feminine, Plural vs Singular, Pronombres, Verbos principales, Verbos Regulares, Regular Verbs, Interrogatives, To be (Permanent), To be (Temporary), Present Progressive, Transportation, Colors, Adjectives, Possessive Pronouns, Possessive Adjective, To want, Food & Drink 1, To want Part two, Places, To go, Going to do, To Have, Tener + verb, Numbers 1-10, Numbers 11-100, Clothing, Emotions, Common Items, To be able to, Opinions, Better & Worse, Thinking, Weather & Nature, Household items & Furniture, Entertainment & Free time, Some more verbs, To know, Clothes, Travel, Commands & Requests, Body parts, Hobbies, Technology, Months.
Write the story so that the user can understand the passage based off of what they have learned and what they are learning.`,
    "9": `A user that is intermediate in the language that is learning these sections: Conditional mood - I would like, Body parts, Physical descriptions, Sports and fitness, Por vs para, More foods, Verbs pt.4, Nature, Society, Future tense, Future tense practice, More future practice.
And has completed these sections: Greetings, Useful Phrases, Useful Phrases: Part 2, Masculine vs Feminine, Plural vs Singular, Pronombres, Verbos principales, Verbos Regulares, Regular Verbs, Interrogatives, To be (Permanent), To be (Temporary), Present Progressive, Transportation, Colors, Adjectives, Possessive Pronouns, Possessive Adjective, To want, Food & Drink 1, To want Part two, Places, To go, Going to do, To Have, Tener + verb, Numbers 1-10, Numbers 11-100, Clothing, Emotions, Common Items, To be able to, Opinions, Better & Worse, Thinking, Weather & Nature, Household items & Furniture, Entertainment & Free time, Some more verbs, To know, Clothes, Travel, Commands & Requests, Body parts, Hobbies, Technology, Months, Past tense - Preterite, More Preterite, Some More Preterite, Even More Preterite, Conditional, Story telling, Past tense - Imperfect, More Imperfect, Some More Imperfect, Even More Imperfect, Cooking & Kitchen, Holidays and Celebrations, Formal, Professions, Reflexive verbs, Shopping & Money, School, Daily Routine, Verbs… Again.
`,
    "10": "Native",
  };

  const levelDescription = levelDescriptions[proficiencyLevel] || "Beginner";

  // This prompt now also mentions the chosen section as a key aspect of the story.
  const userPrompt = `Please generate a passage in the genre of "${genre}" about "${topic}" that is centered around the section "${section}". 
The passage should be engaging and suitable for a ${levelDescription} ${language} learner and should only contain ${language} text. No English.

Make sure to incorporate themes, vocabulary, or ideas that relate to the "${section}" section.
Please make an effort to include as many of the following ${language} words as possible: ${vocabularyList.join(
    ", "
  )}.

Generate exactly four questions about the passage. For each question, provide:
1. The question text.
2. Exactly four multiple-choice options, clearly labeling one as the correct answer.
3. Identify the correct answer among the options.

Ensure that the JSON response strictly follows this structure:

{
  "Name": "Passage Title",
  "Description": "Short description of the passage",
  "Passage": "The full text of the passage. Use \\n to indicate line breaks between paragraphs.",
  "Question_1": "First question about the passage",
  "Answer_1": "The correct answer to Question 1",
  "Options_1": [
    "Option A",
    "Option B",
    "Option C",
    "Option D"
  ],
  "Question_2": "Second question about the passage",
  "Answer_2": "The correct answer to Question 2",
  "Options_2": [
    "Option A",
    "Option B",
    "Option C",
    "Option D"
  ],
  "Question_3": "Third question about the passage",
  "Answer_3": "The correct answer to Question 3",
  "Options_3": [
    "Option A",
    "Option B",
    "Option C",
    "Option D"
  ],
  "Question_4": "Fourth question about the passage",
  "Answer_4": "The correct answer to Question 4",
  "Options_4": [
    "Option A",
    "Option B",
    "Option C",
    "Option D"
  ]
}

**Important Instructions:**
- **Use \\n** to indicate line breaks between paragraphs in the "Passage" field.
- **Exactly four options** must be provided for each question.
- One of the options must **explicitly be the correct answer**.
- The JSON response should **strictly adhere** to the provided structure with no additional text or commentary.
- ****All text returned should be in ${language}******.
- **Output only** the JSON object and nothing else.
`;

  return {
    messages: [
      {
        role: "user",
        content: userPrompt,
      },
    ],
    temperature: 0.7,
    max_tokens: 2000,
  };
}

/**
 * Builds the payload for initial image generation based on a passage.
 * The prompt is constructed using the passage text and default style instructions.
 *
 * @param passage The generated passage text.
 * @param language The target language (used in the style instructions).
 * @returns The payload for the image generation endpoint.
 */
export function buildPassageInitialImagePromptPayload(
  passage: string,
  language: string
) {
  const styleInstructions = `It should be animated yet somewhat realistic. Make it lively with the subject clearly shown. Easy to identify for ${language} learners. The image should be lively, engaging, and detailed.`;

  const prompt = `Create an image based on the following passage: "${passage}". ${styleInstructions}`;

  return {
    prompt,
    model: "dall-e-3",
    n: 1,
    size: "1024x1024",
    response_format: "url",
  };
}

/**
 * Builds the payload for custom image generation based on a passage,
 * using a user-provided style prompt.
 *
 * @param passage The generated passage text.
 * @param stylePrompt The custom style prompt provided by the user.
 * @param language The target language.
 * @returns The payload for the image generation endpoint.
 */
export function buildPassageCustomImagePayload(
  passage: string,
  stylePrompt: string,
  language: string
) {
  const prompt = `Create an image based on the following passage: "${passage}". ${stylePrompt} Make sure it is clear and engaging for ${language} language learners. The image should have no text.`;

  return {
    prompt,
    model: "dall-e-3",
    n: 1,
    size: "1024x1024",
    response_format: "url",
  };
}

// GenAIPrompts.tsx
// In GenAIPrompts/GenAIPrompts.tsx

export function buildTriviaInitialImagePayload(
  topic: string,
  question: string,
  language: string,
  styleInstructions: string // new parameter
) {
  const prompt = `Create an image based on the following topic: "${topic}" and question: "${question}". ${styleInstructions}`;
  return {
    prompt,
    model: "dall-e-3",
    n: 1,
    size: "1024x1024",
    response_format: "url",
  };
}

export function buildTriviaCustomImagePayload(
  topic: string,
  question: string,
  customPrompt: string,
  language: string,
  styleInstructions: string // new parameter
) {
  const prompt = `Create an image based on the following topic: "${topic}" and question: "${question}". ${customPrompt} ${styleInstructions} Make sure it is clear and engaging for ${language} language learners.`;
  return {
    prompt,
    model: "dall-e-3",
    n: 1,
    size: "1024x1024",
    response_format: "url",
  };
}

export function buildGenAiTriviaEnglishPayload(
  difficultyLevel: string,
  genre: string,
  topic: string
) {
  const systemContent = `
You are an assistant that generates multiple-choice trivia questions in English.
Generate exactly 5 questions for the given parameters.
Each question must have exactly 4 answer choices (one correct and 3 plausible distractors).
Return only valid JSON with no extra text, commentary, or code fences. Do not wrap the JSON in markdown formatting.

{
  "topic": "...",
  "questions": [
    { "question": "...", "options": ["...", "...", "...", "..."], "answer": "..." },
    ...
  ]
}
  `.trim();

  const userPrompt = `
Using the following parameters, generate trivia questions in English:
- CEFR Level: ${difficultyLevel} (A1 to C2)
- Genre: ${genre}
- Topic: ${topic}

Generate 5 multiple-choice trivia questions.
Each question must have exactly 4 options, and the correct answer must be one of the options.

Return only valid JSON in the format specified above.
  `.trim();

  return {
    messages: [
      { role: "system", content: systemContent },
      { role: "user", content: userPrompt },
    ],
    max_tokens: 1500,
    temperature: 0.7,
  };
}

// Generates a payload to translate a given set of English questions into a target language.
export function buildGenAiTriviaTranslationPayload(
  difficultyLevel: string,
  genre: string,
  topic: string,
  targetLanguage: string,
  englishQuestions: any[]
) {
  const systemContent = `
You are an assistant that translates multiple-choice trivia questions.
You will receive a set of trivia questions in English.
Translate these questions into ${targetLanguage}, preserving the question text, options, and correct answer.
Return only valid JSON with no extra text, commentary, or code fences. Do not wrap the JSON in markdown formatting.

{
  "language": "${targetLanguage}",
  "questions": [
    { "question": "...", "options": ["...", "...", "...", "..."], "answer": "..." },
    ...
  ]
}
  `.trim();

  // We pass the English questions as context in the user prompt.
  const userPrompt = `
Using the following parameters, translate these trivia questions into ${targetLanguage}:
- CEFR Level: ${difficultyLevel} (A1 to C2)
- Genre: ${genre}
- Topic: ${topic}

English questions:
${JSON.stringify(englishQuestions, null, 2)}

Return only valid JSON in the format specified above.
  `.trim();

  return {
    messages: [
      { role: "system", content: systemContent },
      { role: "user", content: userPrompt },
    ],
    max_tokens: 1000,
    temperature: 0.7,
  };
}

// 1. Build the single-topic prompt
export function buildGenAiTopicPayload(difficultyLevel: string, genre: string) {
  const systemContent = `
You are an assistant that generates trivia topics based on given parameters.
Ensure that your output is strictly valid JSON with no additional commentary.
The JSON must have a single key "topic" whose value is a string representing a trivia subtopic.
  `.trim();

  const userPrompt = `
Generate exactly ONE subtopic for trivia given these parameters:
- CEFR Level: ${difficultyLevel}
- Genre: ${genre}

Return the output as valid JSON, for example:
{
  "topic": "Your subtopic here"
}
  `.trim();

  return {
    messages: [
      {
        role: "system",
        content: systemContent,
      },
      {
        role: "user",
        content: userPrompt,
      },
    ],
    max_tokens: 500,
    temperature: 0.7,
  };
}

/**
 * Generates the payload for creating language-learning sentences.
 *
 * @param section - The selected section (e.g. "Greetings", "Useful Phrases", etc.)
 * @param numSentences - The number of sentences to generate (1-5)
 * @returns A payload object with system and user messages for the API call.
 */
export function buildSentencePayload(section: string, numSentences: number) {
  const systemContent = `
You are an assistant that generates language-learning content in English for students.
Generate exactly ${numSentences} sentence(s) tailored for the "${section}" section.
For each sentence, perform the following:
- Provide the full sentence.
- For "correctWords", list every every word/phrase (make sure that words and phrases formed do not separate any pronouns/verbs/subjects etc.) and punctuation mark present in the sentence as separate elements.
- Generate only between 2 to 4 additional "distractorWords" that are similar in style or content but are incorrect without any punctuation marks.
Here a few examples:-
1. If the sentence is "Could you please help me with my homework?", then "correctWords" should be:
  ["Could you", "please", "help me", "with my", "homework", "?"]. Then "distractorWords" should be:
  ["Can I", "with your", "laundry"]
2. If the sentence is "I would like a glass of water, please.", then "correctWords" should be:
  ["I would like", "a glass of", "water", ",", "please", "."]. Then "distractorWords" should be:
  ["a pot of", "tea"]
3. If the sentence is "The children are playing in the park.", then "correctWords" should be:
  ["The children", "are", "playing", "in", "the park", "."]. Then "distractorWords" should be:
  ["The child", "is", "crying", "the mall"]

Return only valid JSON with no extra text, commentary, or markdown formatting.
Follow this exact JSON format:

{
  "sentences": [
    {
      "sentence": "Full sentence here.",
      "correctWords": ["Word1", "Word2", "..."],
      "distractorWords": ["Distractor1", "Distractor2"] // (Include 2 to 4 distractors as applicable)
    },
    ... (repeat for each sentence)
  ]
}
  `.trim();

  const userPrompt = `
Using the following parameters, generate language-learning content:
- Section: ${section}
- Number of Sentences: ${numSentences}

For each sentence:
1. Create a well-formed sentence relevant to the selected section.
2. Extract every individual word and punctuation mark from the sentence to form the "correctWords" array.
3. Generate between 2 to 4 distractor words/phrases that are similar in style or content but are incorrect.

Return the output strictly in the JSON format specified above.
  `.trim();

  return {
    messages: [
      { role: "system", content: systemContent },
      { role: "user", content: userPrompt },
    ],
    max_tokens: 1500,
    temperature: 0.7,
  };
}

/**
 * Generates the payload for translating the previously generated language-learning content into Spanish.
 *
 * @param originalData - The JSON string output from the sentence generator.
 * @returns A payload object with system and user messages for the translation API call.
 */
export function buildSpanishTranslationPayload(originalData: string) {
  const language = "Spanish";
  const systemContent = `
You are an assistant that translates language-learning content into ${language}.
The input content is in English and includes sentences along with their corresponding correct words and distractor words.
Translate the entire content into ${language}.
Ensure that:
- The JSON structure remains exactly the same.
- Each translation retains the same keys ("sentence", "correctWords", and "distractorWords").
- For each sentence, the "correctWords" array must include every word, punctuation mark, and syntactic element present in the sentence, preserving the original order. For example, for the sentence:
  
  "¿Podrías por favor ayudarme con mi tarea?"
  
  the "correctWords" array should be:
  
  ["¿Podrías", "por favor", "ayudarme", "con mi", "tarea?"]
  
  (i.e. no word or punctuation is omitted).
- For each sentence, generate between 2 to 4 distractor words/phrases that are plausible alternatives yet do not appear in the original sentence.
- The translated content is appropriate for language-learning purposes.

Return only valid JSON with no additional text or formatting.
Follow this exact JSON format:

{
  "translations": [
    {
      "language": "${language}",
      "sentences": [
        {
          "sentence": "Translated sentence",
          "correctWords": ["Translated correct phrase 1", "..."],
          "distractorWords": ["Translated distractor 1", "Translated distractor 2"]
        }
        // ... (for each sentence)
      ]
    }
  ]
}
  `.trim();

  const userPrompt = `
Translate the following language-learning content into ${language}.
Content: ${originalData}
  `.trim();

  return {
    messages: [
      { role: "system", content: systemContent },
      { role: "user", content: userPrompt },
    ],
    max_tokens: 2000,
    temperature: 0.7,
  };
}

/**
 * Generates the payload for translating the previously generated language-learning content into German.
 *
 * @param originalData - The JSON string output from the sentence generator.
 * @returns A payload object with system and user messages for the translation API call.
 */
export function buildGermanTranslationPayload(originalData: string) {
  const language = "German";
  const systemContent = `
You are an assistant that translates language-learning content into ${language}.
The input content is in English and includes sentences along with their corresponding correct words and distractor words.
Translate the entire content into ${language}.
Ensure that:
- The JSON structure remains exactly the same.
- Each translation retains the same keys ("sentence", "correctWords", and "distractorWords").
- For each sentence, the "correctWords" array must include every word, punctuation mark, and syntactic element present in the sentence, preserving the original order. For example, for the sentence:
  
  "Könntest du mir bitte mit meinen Hausaufgaben helfen?"
  
  the "correctWords" array should be:
  
  ["Könntest du", "mir bitte", "mit meinen", "Hausaufgaben", "helfen?"]
  
  (i.e. no word or punctuation is omitted).
- For each sentence, generate between 2 to 4 distractor words/phrases that are plausible alternatives yet do not appear in the original sentence.
- The translated content is appropriate for language-learning purposes.

Return only valid JSON with no additional text or formatting.
Follow this exact JSON format:

{
  "translations": [
    {
      "language": "${language}",
      "sentences": [
        {
          "sentence": "Translated sentence",
          "correctWords": ["Translated correct phrase 1", "..."],
          "distractorWords": ["Translated distractor 1", "Translated distractor 2"]
        }
        // ... (for each sentence)
      ]
    }
  ]
}
  `.trim();

  const userPrompt = `
Translate the following language-learning content into ${language}.
Content: ${originalData}
  `.trim();

  return {
    messages: [
      { role: "system", content: systemContent },
      { role: "user", content: userPrompt },
    ],
    max_tokens: 2000,
    temperature: 0.7,
  };
}

/**
 * Generates the payload for translating the previously generated language-learning content into Italian.
 *
 * @param originalData - The JSON string output from the sentence generator.
 * @returns A payload object with system and user messages for the translation API call.
 */
export function buildItalianTranslationPayload(originalData: string) {
  const language = "Italian";
  const systemContent = `
You are an assistant that translates language-learning content into ${language}.
The input content is in English and includes sentences along with their corresponding correct words and distractor words.
Translate the entire content into ${language}.
Ensure that:
- The JSON structure remains exactly the same.
- Each translation retains the same keys ("sentence", "correctWords", and "distractorWords").
- For each sentence, the "correctWords" array must include every word, punctuation mark, and syntactic element present in the sentence, preserving the original order. For example, for the sentence:
  
  "Potresti per favore aiutarmi con i miei compiti?"
  
  the "correctWords" array should be:
  
  ["Potresti", "per favore", "aiutarmi", "con i miei", "compiti?"]
  
  (i.e. no word or punctuation is omitted).
- For each sentence, generate between 2 to 4 distractor words/phrases that are plausible alternatives yet do not appear in the original sentence.
- The translated content is appropriate for language-learning purposes.

Return only valid JSON with no additional text or formatting.
Follow this exact JSON format:

{
  "translations": [
    {
      "language": "${language}",
      "sentences": [
        {
          "sentence": "Translated sentence",
          "correctWords": ["Translated correct phrase 1", "..."],
          "distractorWords": ["Translated distractor 1", "Translated distractor 2"]
        }
        // ... (for each sentence)
      ]
    }
  ]
}
  `.trim();

  const userPrompt = `
Translate the following language-learning content into ${language}.
Content: ${originalData}
  `.trim();

  return {
    messages: [
      { role: "system", content: systemContent },
      { role: "user", content: userPrompt },
    ],
    max_tokens: 2000,
    temperature: 0.7,
  };
}

/**
 * Generates the payload for translating the previously generated language-learning content into French.
 *
 * @param originalData - The JSON string output from the sentence generator.
 * @returns A payload object with system and user messages for the translation API call.
 */
export function buildFrenchTranslationPayload(originalData: string) {
  const language = "French";
  const systemContent = `
You are an assistant that translates language-learning content into ${language}.
The input content is in English and includes sentences along with their corresponding correct words and distractor words.
Translate the entire content into ${language}.
Ensure that:
- The JSON structure remains exactly the same.
- Each translation retains the same keys ("sentence", "correctWords", and "distractorWords").
- For each sentence, the "correctWords" array must include every word, punctuation mark, and syntactic element present in the sentence, preserving the original order. For example, for the sentence:
  
  "Pourriez-vous s'il vous plaît m'aider avec mes devoirs?"
  
  the "correctWords" array should be:
  
  ["Pourriez-vous", "s'il vous plaît", "m'aider", "avec mes", "devoirs?"]
  
  (i.e. no word or punctuation is omitted).
- For each sentence, generate between 2 to 4 distractor words/phrases that are plausible alternatives yet do not appear in the original sentence.
- The translated content is appropriate for language-learning purposes.

Return only valid JSON with no additional text or formatting.
Follow this exact JSON format:

{
  "translations": [
    {
      "language": "${language}",
      "sentences": [
        {
          "sentence": "Translated sentence",
          "correctWords": ["Translated correct phrase 1", "..."],
          "distractorWords": ["Translated distractor 1", "Translated distractor 2"]
        }
        // ... (for each sentence)
      ]
    }
  ]
}
  `.trim();

  const userPrompt = `
Translate the following language-learning content into ${language}.
Content: ${originalData}
  `.trim();

  return {
    messages: [
      { role: "system", content: systemContent },
      { role: "user", content: userPrompt },
    ],
    max_tokens: 2000,
    temperature: 0.7,
  };
}

/**
 * Generates the payload for translating the previously generated language-learning content into Portuguese.
 *
 * @param originalData - The JSON string output from the sentence generator.
 * @returns A payload object with system and user messages for the translation API call.
 */
export function buildPortugueseTranslationPayload(originalData: string) {
  const language = "Portuguese";
  const systemContent = `
You are an assistant that translates language-learning content into ${language}.
The input content is in English and includes sentences along with their corresponding correct words and distractor words.
Translate the entire content into ${language}.
Ensure that:
- The JSON structure remains exactly the same.
- Each translation retains the same keys ("sentence", "correctWords", and "distractorWords").
- For each sentence, the "correctWords" array must include every word, punctuation mark, and syntactic element present in the sentence, preserving the original order. For example, for the sentence:
  
  "Você poderia, por favor, me ajudar com minha lição de casa?"
  
  the "correctWords" array should be:
  
  ["Você poderia", "por favor", "me ajudar", "com minha", "lição de casa?"]
  
  (i.e. no word or punctuation is omitted).
- For each sentence, generate between 2 to 4 distractor words/phrases that are plausible alternatives yet do not appear in the original sentence.
- The translated content is appropriate for language-learning purposes.

Return only valid JSON with no additional text or formatting.
Follow this exact JSON format:

{
  "translations": [
    {
      "language": "${language}",
      "sentences": [
        {
          "sentence": "Translated sentence",
          "correctWords": ["Translated correct phrase 1", "..."],
          "distractorWords": ["Translated distractor 1", "Translated distractor 2"]
        }
        // ... (for each sentence)
      ]
    }
  ]
}
  `.trim();

  const userPrompt = `
Translate the following language-learning content into ${language}.
Content: ${originalData}
  `.trim();

  return {
    messages: [
      { role: "system", content: systemContent },
      { role: "user", content: userPrompt },
    ],
    max_tokens: 2000,
    temperature: 0.7,
  };
}

// src/Users/Users.tsx
import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Define the props and event structure
interface UsersProps {
  timeframe: string; // "daily", "weekly", or "monthly"
  events: UserEvent[];
  startDate: string; // "YYYY-MM-DD" (from parent)
  endDate: string; // "YYYY-MM-DD" (from parent)
}

interface UserEvent {
  userId: string;
  date: string; // e.g. "2025-01-10T14:22:00Z"
}

const Users: React.FC<UsersProps> = ({
  timeframe,
  events,
  startDate,
  endDate,
}) => {
  // Memoize the calculation logic for performance optimization
  const { labels, newUsersArr, returningArr } = useMemo(() => {
    // Handle the case where there are no events or invalid range
    if (!events || events.length === 0) {
      return {
        labels: [] as string[],
        newUsersArr: [] as number[],
        returningArr: [] as number[],
      };
    }

    // Convert date strings to Date objects for range computation
    const rangeStart = new Date(startDate);
    const rangeEnd = new Date(endDate);

    // Generate labels based on the timeframe (daily, weekly, monthly)
    const allLabels =
      timeframe === "daily"
        ? getAllDaysInRange(rangeStart, rangeEnd)
        : timeframe === "weekly"
        ? getAllWeeksInRange(rangeStart, rangeEnd)
        : getAllMonthsInRange(rangeStart, rangeEnd);

    // Initialize buckets for new and returning users for each label
    const buckets: Record<
      string,
      { newUsers: Set<string>; returningUsers: Set<string> }
    > = {};
    for (const label of allLabels) {
      buckets[label] = { newUsers: new Set(), returningUsers: new Set() };
    }

    // Track the first login bucket for each user
    const userFirstLogin: Record<string, string> = {};

    // Populate buckets with event data
    for (const event of events) {
      const eventDate = new Date(event.date);
      const bucketLabel = getBucketLabel(timeframe, eventDate);

      // Skip events outside the range
      if (!buckets[bucketLabel]) continue;

      if (!userFirstLogin[event.userId]) {
        // If it's the user's first login, mark them as a new user
        userFirstLogin[event.userId] = bucketLabel;
        buckets[bucketLabel].newUsers.add(event.userId);
      } else if (userFirstLogin[event.userId] !== bucketLabel) {
        // If not the first login, mark them as a returning user
        buckets[bucketLabel].returningUsers.add(event.userId);
      }
    }

    // Convert the bucketed data into arrays for Chart.js
    const newUsersArr = allLabels.map((label) => buckets[label].newUsers.size);
    const returningArr = allLabels.map(
      (label) => buckets[label].returningUsers.size
    );

    return { labels: allLabels, newUsersArr, returningArr };
  }, [events, timeframe, startDate, endDate]);

  // Render message if no labels are generated
  if (labels.length === 0) {
    return (
      <div>
        <h2>New vs Returning Users</h2>
        <p>No user events in this range.</p>
      </div>
    );
  }

  // Prepare chart data for rendering
  const chartData = {
    labels,
    datasets: [
      {
        label: "New Users",
        data: newUsersArr,
        borderColor: "#DC3912",
        backgroundColor: "#DC3912",
      },
      {
        label: "Returning Users",
        data: returningArr,
        borderColor: "#3366CC",
        backgroundColor: "#3366CC",
      },
    ],
  };

  return (
    <div>
      <h2>New vs Returning Users</h2>
      <Line data={chartData} />
    </div>
  );
};

export default Users;

// Helper function to generate a bucket label based on the timeframe
function getBucketLabel(timeframe: string, dt: Date): string {
  if (timeframe === "daily") {
    return dt.toISOString().split("T")[0];
  } else if (timeframe === "weekly") {
    const sunday = new Date(dt);
    sunday.setDate(sunday.getDate() - sunday.getDay());
    return sunday.toISOString().split("T")[0];
  } else {
    const y = dt.getFullYear();
    const m = String(dt.getMonth() + 1).padStart(2, "0");
    return `${y}-${m}`;
  }
}

// Generate an array of daily labels
function getAllDaysInRange(start: Date, end: Date): string[] {
  const days: string[] = [];
  const current = new Date(start);
  while (current <= end) {
    days.push(current.toISOString().split("T")[0]);
    current.setDate(current.getDate() + 1);
  }
  return days;
}

// Generate an array of weekly labels (Sundays)
function getAllWeeksInRange(start: Date, end: Date): string[] {
  const weeks: string[] = [];
  const startSunday = new Date(start);
  startSunday.setDate(startSunday.getDate() - startSunday.getDay());
  const endSunday = new Date(end);
  endSunday.setDate(endSunday.getDate() - endSunday.getDay());

  let current = startSunday;
  while (current <= endSunday) {
    weeks.push(current.toISOString().split("T")[0]);
    current.setDate(current.getDate() + 7);
  }
  return weeks;
}

// Generate an array of monthly labels (YYYY-MM)
function getAllMonthsInRange(start: Date, end: Date): string[] {
  const months: string[] = [];
  let cYear = start.getFullYear();
  let cMonth = start.getMonth();
  const endYear = end.getFullYear();
  const endMonth = end.getMonth();

  while (cYear < endYear || (cYear === endYear && cMonth <= endMonth)) {
    const mm = String(cMonth + 1).padStart(2, "0");
    months.push(`${cYear}-${mm}`);
    cMonth++;
    if (cMonth > 11) {
      cMonth = 0;
      cYear++;
    }
  }
  return months;
}

import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface FlashcardData {
  user_id: string;
  event: string;
  event_detail: string;
  event_detail_2: string;
  event_detail_3: string;
  event_timestamp: string;
  device_id: string;
  section_level: string;
  section: string;
  session_id: string;
  event_type: string;
  event_id: string;
}

// Structure for grouped data
interface GroupedData {
  [section: string]: {
    known: number;
    review: number;
  };
}

const Flashcard: React.FC = () => {
  const [flashcardData, setFlashcardData] = useState<FlashcardData[]>([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from the given Flashcard endpoint
  const fetchFlashcardData = async () => {
    try {
      const response = await fetch(
        "https://ob38jihfoj.execute-api.us-east-1.amazonaws.com/dev/api/v1/metric-queries/e68016c1-90a6-468d-ab68-86243697d2df"
      );
      const apiData = await response.json();
      // Transform the raw API data to our desired structure
      const transformed = transformFlashcardData(apiData);
      setFlashcardData(transformed);
    } catch (error) {
      console.error("Error fetching flashcard data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Transform the raw data from the API
  const transformFlashcardData = (apiData: any[]): FlashcardData[] => {
    // The data structure is [ [header...], [ [row], [row], ... ] ]
    if (!apiData || !apiData[1] || !apiData[1].length) return [];

    // Skips the top-level header row, then the internal header row, to get just the event rows
    return apiData[1].slice(1).map((row: string[]) => ({
      user_id: row[0],
      event: row[1],
      event_detail: row[2],
      event_detail_2: row[3],
      event_detail_3: row[4],
      event_timestamp: row[5],
      device_id: row[6],
      section_level: row[7],
      section: row[8],
      session_id: row[9],
      event_type: row[10],
      event_id: row[11],
    }));
  };

  // Helper function to process flashcard data for the stacked bar chart
  const processFlashcardData = () => {
    const groupedData: GroupedData = {};

    flashcardData.forEach((event) => {
      const section = event.section || "Unknown Section";
      if (!groupedData[section]) {
        groupedData[section] = { known: 0, review: 0 };
      }

      // event_type === "mark_correct" => known
      // event_type === "mark_for_review" => review
      if (event.event_type === "mark_correct") {
        groupedData[section].known += 1;
      } else if (event.event_type === "mark_for_review") {
        groupedData[section].review += 1;
      }
    });

    const labels = Object.keys(groupedData);

    return {
      labels,
      knownData: labels.map((section) => groupedData[section].known),
      reviewData: labels.map((section) => groupedData[section].review),
      totalData: labels.map(
        (section) => groupedData[section].known + groupedData[section].review
      ),
    };
  };

  useEffect(() => {
    fetchFlashcardData();
  }, []);

  if (loading) {
    return <div>Loading Flashcard data...</div>;
  }

  // Prepare the chart data
  const { labels, knownData, reviewData, totalData } = processFlashcardData();

  const flashcardsChartData = {
    labels,
    datasets: [
      {
        label: "Known",
        data: knownData,
        backgroundColor: "#3366cc",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Review",
        data: reviewData,
        backgroundColor: "#dc3912",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Custom tooltip to show percentage
  const barOptions = {
    plugins: {
      legend: {
        position: "top" as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const datasetIndex = context.datasetIndex; // 0 -> Known, 1 -> Review
            const dataIndex = context.dataIndex; // index of the label
            const label = context.dataset.label; // "Known" or "Review"
            const value = context.parsed.y; // numeric value of this bar
            const total = totalData[dataIndex] || 0;

            let percentage = 0;
            if (total > 0) {
              percentage = (value / total) * 100;
            }
            const percentageStr = percentage.toFixed(2);

            return `${label}: ${value} of ${total} (${percentageStr}%)`;
          },
        },
      },
    },
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };

  return (
    <div>
      <h2>Flashcards: Known vs. Review</h2>
      <Bar data={flashcardsChartData} options={barOptions} />
    </div>
  );
};

export default Flashcard;

// src/ChatterboxMetrics.tsx
import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Logins from "./LogIns/LogIns";
import Users from "./Users/Users";
import TopUsers from "./TopUsers/TopUsers";
import AvgTime from "./AvgTime/AvgTime";
import Duration from "./Duration/Duration";
import styles from "./Metrics.module.css";

const PRESET_EXCLUDED = [
  "ravi@gmail.com",
  "grantjohnson654@gmail.com",
  "granty100@gmail.com",
  "Ravi@gmail.com",
  "UnregisteredUser",
];

export interface AvgTimeEvent {
  userId: string;
  date: string;
  duration: number;
}

// Helper to get today's date in YYYY-MM-DD
function getToday(): string {
  const today = new Date();
  return today.toISOString().split("T")[0]; // e.g. "2025-01-13"
}

// Helper to get date 2 months ago in YYYY-MM-DD
function getTwoMonthsAgo(): string {
  const d = new Date();
  d.setMonth(d.getMonth() - 2);
  return d.toISOString().split("T")[0]; // e.g. "2024-11-13"
}

const ChatterboxMetrics: React.FC = () => {
  // Timeframe used by these 3 charts
  const [timeframe, setTimeframe] = useState("weekly");
  const [apiData, setApiData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  // Date range
  const [startDate, setStartDate] = useState(getTwoMonthsAgo());
  const [endDate, setEndDate] = useState(getToday());

  // List of excluded users
  const [excludedUsers, setExcludedUsers] = useState<string[]>(PRESET_EXCLUDED);

  // If a user is selected in "Top Users," we show ONLY that user's data in the other charts
  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  // For controlling the input that adds a new excluded user
  const [excludeInput, setExcludeInput] = useState("");

  // ----------------------------------------
  // 1. Fetch Data
  // ----------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          "https://ob38jihfoj.execute-api.us-east-1.amazonaws.com/dev/api/v1/metric-queries/542bafbf-ee1d-4368-b2ac-0263bd532676"
        );
        const json = await response.json();
        setApiData(json);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // ----------------------------------------
  // 2. Data Transformation / Filtering
  // ----------------------------------------
  const allEvents = useMemo(() => {
    if (!apiData || !apiData[1]) return [];
    return apiData[1].slice(1).map((row: string[]) => ({
      userId: row[0],
      date: row[5],
    }));
  }, [apiData]);

  // Filter by date range
  const filteredByDate = useMemo(() => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return allEvents.filter((evt: { date: string | number | Date }) => {
      const d = new Date(evt.date);
      return d >= start && d <= end;
    });
  }, [allEvents, startDate, endDate]);

  // Exclude certain users and/or filter to selectedUser
  const filteredAllEvents = useMemo(() => {
    return filteredByDate.filter((evt: { userId: string }) => {
      if (excludedUsers.includes(evt.userId)) return false;
      if (selectedUser && evt.userId !== selectedUser) return false;
      return true;
    });
  }, [filteredByDate, excludedUsers, selectedUser]);

  // src/ChatterboxMetrics.tsx
  // Add new state
  const [avgTimeData, setAvgTimeData] = useState<any[]>([]);

  // Add new useEffect for average time data
  useEffect(() => {
    const fetchAvgTimeData = async () => {
      try {
        const response = await fetch(
          "https://ob38jihfoj.execute-api.us-east-1.amazonaws.com/dev/api/v1/metric-queries/e277cd60-7286-4ada-8b78-6f5bb072945e"
        );
        const json = await response.json();
        console.log("Fetched avgTimeData:", json); // Debug log
        setAvgTimeData(json);
      } catch (err) {
        console.error("Error fetching average time data:", err);
      }
    };
    fetchAvgTimeData();
  }, []);

  // Add data transformation for average time events
  const avgTimeEvents = useMemo(() => {
    if (!avgTimeData || !avgTimeData[1]) return [];
    return avgTimeData[1].slice(1).map((row: string[]) => ({
      userId: row[0],
      date: row[5],
      duration:
        parseInt(row[2].replace("Duration: ", "").replace("s", "")) || 0,
    }));
  }, [avgTimeData]);

  // Add filtering for avgTimeEvents
  // Update the filteredAvgTimeEvents memo
  const filteredAvgTimeEvents = useMemo(() => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    return avgTimeEvents.filter((evt: AvgTimeEvent) => {
      const d = new Date(evt.date);
      return (
        d >= start &&
        d <= end &&
        !excludedUsers.includes(evt.userId) &&
        (!selectedUser || evt.userId === selectedUser)
      );
    });
  }, [avgTimeEvents, startDate, endDate, excludedUsers, selectedUser]);

  // ----------------------------------------
  // 3. Event Handlers
  // ----------------------------------------
  const handleUserClick = (clickedUser: string) => {
    if (selectedUser === clickedUser) {
      setSelectedUser(null);
    } else {
      setSelectedUser(clickedUser);
    }
  };

  const handleExcludeUser = () => {
    const email = excludeInput.trim().toLowerCase();
    if (email && !excludedUsers.includes(email)) {
      setExcludedUsers((prev) => [...prev, email]);
    }
    setExcludeInput("");
  };

  const handleIncludeUser = (userEmail: string) => {
    setExcludedUsers((prev) => prev.filter((u) => u !== userEmail));
  };

  // ----------------------------------------
  // 4. Render
  // ----------------------------------------
  if (loading) {
    return <div>Loading metrics data...</div>;
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Chatterbox Metrics</h1>

      {/* Date Range Inputs */}
      <div className={styles.dateRangeContainer}>
        <div>
          <label>Start Date: </label>
          <input
            className={styles.dateInput}
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>

        <div>
          <label>End Date: </label>
          <input
            className={styles.dateInput}
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>

      {/* Timeframe dropdown */}
      <div className={styles.timeframeSelector}>
        <label htmlFor="timeframe">Select Timeframe: </label>
        <select
          id="timeframe"
          value={timeframe}
          onChange={(e) => setTimeframe(e.target.value)}
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>

      {/* Excluded Users Management */}
      <div className={styles.excludedUsers}>
        <h2>Excluded Users</h2>
        <p>These emails will not appear in the metrics.</p>
        <ul className={styles.excludedList}>
          {excludedUsers.map((email) => (
            <li key={email} className={styles.excludedListItem}>
              <span>{email}</span>
              <button
                onClick={() => handleIncludeUser(email)}
                className={styles.includeButton}
              >
                Include
              </button>
            </li>
          ))}
        </ul>

        <div className={styles.excludeInputContainer}>
          <input
            type="text"
            placeholder="Email to exclude..."
            value={excludeInput}
            onChange={(e) => setExcludeInput(e.target.value)}
            className={styles.excludeInput}
          />
          <button onClick={handleExcludeUser} className={styles.excludeButton}>
            Exclude
          </button>
        </div>
      </div>

      {/* If we have selectedUser, let's display a small note */}
      {selectedUser && (
        <div className={styles.selectedUserNote}>
          Currently filtering charts to only show:{" "}
          <strong>{selectedUser}</strong>{" "}
          <button
            onClick={() => setSelectedUser(null)}
            className={styles.clearFilterButton}
          >
            (Clear)
          </button>
        </div>
      )}

      {/* Chart container for first three charts */}
      <div className={styles.chartContainer}>
        {/* 1) Logins */}
        <div className={styles.chart}>
          <Logins
            timeframe={timeframe}
            events={filteredAllEvents}
            startDate={startDate}
            endDate={endDate}
          />
        </div>

        {/* 2) Users */}
        <div className={styles.chart}>
          <Users
            timeframe={timeframe}
            events={filteredAllEvents}
            startDate={startDate}
            endDate={endDate}
          />
        </div>

        {/* 3) TopUsers */}
        <div className={styles.chart}>
          <TopUsers
            timeframe={timeframe}
            events={filteredByDate.filter(
              (evt: { userId: string }) => !excludedUsers.includes(evt.userId)
            )}
            onUserClick={handleUserClick}
          />
        </div>

        {/* 4) Average Time Spent */}
        <div className={styles.chart}>
          <AvgTime
            timeframe={timeframe}
            events={filteredAvgTimeEvents}
            startDate={startDate}
            endDate={endDate}
          />
        </div>

        {/* 5) Duration Histogram */}
        <div className={styles.chart}>
          <Duration events={filteredAvgTimeEvents} />
        </div>
      </div>

      <Link to="/metrics/course" className={styles.courseLink}>
        View Course Charts
      </Link>
    </div>
  );
};

export default ChatterboxMetrics;
